import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { alertActions, curriculumActions, teacherActions } from "../../../_actions";
import { isEmpty } from "lodash";
import { Link, useParams } from "react-router-dom";
import { PopUpFilter, PopUpYesNo } from "../../../_components/Popup";
import { Header } from "../../../_components/Admin/Header";
import {
  popupConstants,
  curriculumConstants,
  teacherConstants,
  userConstants,
} from "../../../_constants";
import { ContentSkill } from "../Curriculum";
import { useHistory } from "react-router-dom";
import { teacherService } from "../../../_services";
import { TypeAssignExercise } from "../../../_constants/typeAssignExercise";
import { configConstants } from "../../../_constants";
import { hasDuplicates } from "../../../_base/Validate";
import { classesConstants } from "../../../_constants/classes";
import { apiCaller } from "../../../_helpers";
import { Alert } from "../../../_components/Alert";
import moment from "moment";

function AddCurriculumExercise() {
  const { id } = useParams();
  const history = useHistory();

  const search = history?.location?.search;
  const params = new URLSearchParams(search);
  const typeAssign = params?.get("assign");
  const userID = params?.get("user_id");
  const fromPage = params?.get("page");
  const dispatch = useDispatch();
  const [type, setType] = useState(
    // localStorage.getItem("typeCurrExercise") || "sunday"
    "sunday"
  );
  const curriculums = useSelector((state) => state.curriculums);
  const authentication = useSelector((state) => state.authentication);
  const alert = useSelector((state) => state.alert);
  const Popup = useSelector((state) => state.Popup);
  const [isLoading, setLoading] = useState(false);
  const [isLoadingDetail, setLoadingDetail] = useState(false);
  const paramFilter = useSelector((state) => state.curriculums.filters.param);

  const exercises = useSelector((state) => state.classes.exercises);
  const dataAssignProposal = useSelector(
    (state) => state.classes.data_assign_proposal
  );
  const persistCurriculumTeacher = useSelector((state) => state.persistCurriculumTeacher);

  const [listCurriculums, setListCurriculums] = useState(curriculums);

  const currInfoStorage = JSON.parse(localStorage.getItem("curriculum_info"));

  const [curriculumId, setnitCurriculumId] = useState(
    !!currInfoStorage?.id
      ? currInfoStorage?.id
      : curriculums.detail.course
      ? curriculums.detail.course.id
      : 0
  );

  const [curriculumName, setnitCurriculumName] = useState(
    !!currInfoStorage?.name
      ? currInfoStorage?.name
      : curriculums.detail.course
      ? curriculums.detail.course.name
      : ""
  );
  const [subType, setSubType] = useState(
    !!currInfoStorage?.subType
      ? currInfoStorage?.subType
      : curriculums?.detail?.course
      ? curriculums?.detail?.course?.subType
      : null
  );

  const dataExercise = dataAssignProposal.reduce(function (accumulator, obj) {
    return accumulator.concat(obj.exercise_suggest);
  }, []);

  const [isLoadMore, setLoadMore] = useState(true);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    // dispatch(teacherActions.getCurriculum('sunday', true));
    setLoading(true);
    teacherService
      .getCurriculum(type == "personal" ? "personal" : "sunday")
      .then((curriculums) => {
        dispatch({
          type: curriculumConstants.GET_LIST_CURRICULUM_EXERCISE,
          curriculums: curriculums.courses,
        });
        setLoading(false);
      });
    dispatch({
      type: curriculumConstants.RESET_DETAIL,
    });
    // setnitCurriculumId(0);

    // return () => {
    //     dispatch({
    //         type: curriculumConstants.CLEAR_PARAM_FILTER_CURRICULUM
    //     });
    // }
  }, []);

  // Handle Change Type
  function changeType(type) {
    setLoadMore(true);
    setOffset(0);
    setType(type);
    localStorage.setItem("typeCurrExercise", type);
    setLoading(true);
    localStorage.removeItem("curriculum_info");
    if (type === "favorite") {
      dispatch({
        type: curriculumConstants.GET_LIST_CURRICULUM_FAVORITE_START,
      });
      // dispatch(teacherActions.getCurriculumFavorite(authentication.id));
      teacherActions
        .getCurriculumFavoriteV2(
          authentication.id,
          configConstants.DEFAULT_LIMIT,
          0
        )
        .then((curriculums) => {
          dispatch({
            type: curriculumConstants.GET_LIST_CURRICULUM_FAVORITE,
            curriculums,
          });
          setLoading(false);
        });
    } else {
      // dispatch(teacherActions.getCurriculum(type, true));
      teacherService.getCurriculum(type).then((curriculums) => {
        dispatch({
          type: curriculumConstants.GET_LIST_CURRICULUM_EXERCISE,
          curriculums: curriculums.courses,
        });
        setLoading(false);
      });
    }
  }

  const [openPopUpBack, setOpenPopUpBack] = useState(false);
  const [unitIdSelected, setUnitIdSelected] = useState(false);

  const fetchDataCurriculumFavorite = (offset_Data) => {
    if ((!isLoading && offset != offset_Data) || offset_Data == 0) {
      setLoading(true);
      teacherActions
        .getCurriculumFavoriteV2(
          authentication.id,
          configConstants.DEFAULT_LIMIT,
          offset_Data
        )
        .then((curriculum) => {
          if (!hasDuplicates(curriculum?.data, curriculums?.data)) {
            let curriculumsSide = {
              ...curriculums,
              data: curriculums?.data?.concat(curriculum?.data),
            };
            dispatch({
              type: curriculumConstants.GET_LIST_CURRICULUM_FAVORITE,
              curriculums: curriculumsSide,
            });
            setLoading(false);
            setOffset(offset_Data);
            setLoadMore(
              curriculum?.data?.length >= configConstants.DEFAULT_LIMIT
            );
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  function getDetailCurriculum(id, name, subType) {
    // dispatch(teacherActions.getDetailCurriculumById(id));
    setLoadingDetail(true);
    if (!!subType) {
      teacherService.getDetailCurriculumPreparationCourse(id).then((res) => {
        if (res) {
          const listData = res?.data
          dispatch({
            type: curriculumConstants.GET_DETAIL_CURRICULUM,
            curriculum: {
              ...res,
              data_lesson: {
                data: [...listData],
                unit_name: listData?.map(item => ({
                  list_skill: [],
                  ...item
                }))
              },
            },
          });
          setLoadingDetail(false);
        }
      });
    } else {
      // teacherService.getDetailCurriculumById(id).then((curriculum) => {
      //   dispatch({
      //     type: curriculumConstants.GET_DETAIL_CURRICULUM,
      //     curriculum,
      //   });
      //   setLoadingDetail(false);
      // });
      curriculumActions.getDataMap(id).then((res) => {
        const listData = res?.unit_list?.map(item => {
          return {
            ...item,
            stt: !!item?.stt ? `Unit ${item?.stt}` : item?.stt,
            curriculum_id: id,
            curriculum_name: curriculums?.data?.find(item => item?.id == id)?.name
          }
        })
        const curriculum = {
          // ...res,
          data_lesson: {
            data: [...listData],
            unit_name: listData?.map(item => ({
              list_skill: [],
              ...item
            }))
          },
        }
        dispatch({
          type: curriculumConstants.GET_DETAIL_CURRICULUM,
          curriculum,
        });
        setLoadingDetail(false);
      });
    }
    setnitCurriculumId(id);
    setnitCurriculumName(name);
    setSubType(subType);
    localStorage.setItem(
      "curriculum_info",
      JSON.stringify({
        id: id,
        name: name,
        subType
      })
    );
  }

  const handleGetListSkill = (e, detailUnit) => {
    if (unitIdSelected === detailUnit.unit_id) {
      setUnitIdSelected('')
      return;
    }
    if (!!detailUnit?.list_skill?.length) {
      setUnitIdSelected(detailUnit?.unit_id);
      return;
    }
    if (!subType) {
      console.log("=====c5")
      const dataUnit = persistCurriculumTeacher?.dataUnitByCurriculum?.[detailUnit?.curriculum_id]?.[detailUnit.unit_id];
      if (!!dataUnit?.data?.length && !!dataUnit?.saveAt && moment(new Date()).diff(moment(dataUnit?.saveAt), 'hours') < 6) {
        dispatch({
          type: curriculumConstants.LESSON_BY_SKILL_TEACHER,
          lesson_skill: {
            data: dataUnit?.data,
            base_url: dataUnit?.base_url
          },
        });
        const listSkill = dataUnit?.data;
        const curriculum = {
          ...curriculums.detail,
          data_lesson: {
            ...curriculums.detail.data_lesson,
            unit_name: curriculums.detail.data_lesson?.unit_name?.map(item => {
              if (item?.unit_id == detailUnit?.unit_id) {
                return {
                  ...item,
                  list_skill: listSkill
                }
              }
              return item;
            })
          },
        }
        dispatch({
          type: curriculumConstants.GET_DETAIL_CURRICULUM,
          curriculum,
        });
        setUnitIdSelected(detailUnit.unit_id)
      } else {
        curriculumActions.getDataUserInUnit(detailUnit.unit_id).then(resDataUser => {
          curriculumActions.getLessonByUnit(detailUnit.unit_id).then((res) => {
            const skill_list = res?.skill_list?.map(item => {
              const dataUser = resDataUser?.skill_list?.find(data => data?.skill === item?.skill)
              return {
                ...item,
                list_lesson: item?.list_lesson?.map(lesson => {
                  return {
                    ...lesson,
                    unit_id: detailUnit.unit_id,
                    unit_name: detailUnit?.unit_name,
                    curriculum_id: detailUnit?.curriculum_id,
                    curriculum_name: detailUnit?.curriculum_name,
                    is_lock_lesson: dataUser?.is_lock_lesson
                  }
                })
              }
            })
            dispatch({
              type: curriculumConstants.LESSON_BY_SKILL_TEACHER,
              lesson_skill: {
                data: skill_list,
                base_url: res?.base_url
              },
            });
            dispatch({
              type: curriculumConstants.SAVE_DETAIL_UNIT_TEACHER,
              payload: {
                data: skill_list,
                base_url: res?.base_url,
                curriculumId: detailUnit?.curriculum_id,
                unitId: detailUnit.unit_id
              },
            });
            const listSkill = res?.skill_list;
            const curriculum = {
              ...curriculums.detail,
              data_lesson: {
                ...curriculums.detail.data_lesson,
                unit_name: curriculums.detail.data_lesson?.unit_name?.map(item => {
                  if (item?.unit_id == detailUnit?.unit_id) {
                    return {
                      ...item,
                      list_skill: listSkill
                    }
                  }
                  return item;
                })
              },
            }
            dispatch({
              type: curriculumConstants.GET_DETAIL_CURRICULUM,
              curriculum,
            });
          }).finally(() => {
            setUnitIdSelected(detailUnit.unit_id)
          });
        })
      }
      return;
    }
    if (detailUnit?.has_compostition === true) {
      e.preventDefault();
      apiCaller('/api_preparation_course/composition?unit_id=' + detailUnit?.unit_id + "&curriculum_id=" + detailUnit?.curriculum_id + "&composition_id=" + detailUnit?.id, "GET").then((res) => {
        console.log('res composition: ', res)
        dispatch({
          type: curriculumConstants.GET_DETAIL_CURRICULUM,
          curriculum: {
            ...curriculums?.detail,
            data_lesson: {
              ...curriculums?.detail?.data_lesson,
              unit_name: curriculums?.detail?.data_lesson?.unit_name?.map(item => ({
                ...item,
                list_topic: res?.data
              }))
            }
          },
        });
      }).finally(() => {
        unitIdSelected === detailUnit.unit_id ? setUnitIdSelected("") : setUnitIdSelected(detailUnit.unit_id)
      })
    } else if (detailUnit?.has_compostition === false) {
      apiCaller(
        `/api_preparation_course/list_topic?unit_id=${detailUnit?.unit_id}&curriculum_id=${detailUnit?.curriculum_id}&composition_id=${detailUnit?.id}`, "GET").then((res) => {
        dispatch({
          type: curriculumConstants.GET_DETAIL_CURRICULUM,
          curriculum: {
            ...curriculums?.detail,
            data_lesson: {
              ...curriculums?.detail?.data_lesson,
              unit_name: curriculums?.detail?.data_lesson?.unit_name?.map(item => ({
                ...item,
                list_topic: res?.data?.list_topic
              }))
            }
          },
        });
      }).finally(() => {
        unitIdSelected === detailUnit.unit_id ? setUnitIdSelected("") : setUnitIdSelected(detailUnit.unit_id)
      })
    }
  }

  function setshowFilter() {
    dispatch({
      type: popupConstants.SHOW_FORM_POPUP,
      data: {
        showFilterCurriculum: true,
      },
    });
  }

  function setcloseFilter() {
    dispatch({
      type: popupConstants.CLEAR_ALL_POPUP,
    });
  }

  function componentFilter() {
    return (
      <div
        className='form-sunE-button btn-filter rel'
        onClick={() => setshowFilter()}
      >
        <img
          src={
            _.isEmpty(paramFilter.skill) &&
            _.isEmpty(paramFilter.type) &&
            _.isEmpty(paramFilter.grade_id)
              ? `/assets/images/teacher/icon_not_yet_filter.png`
              : `/assets/images/teacher/ico_filter.png`
          }
          className='ml--5'
          alt='ico_add'
        />
        <button className='btn-line-blue'>Lọc</button>
      </div>
    );
  }

  // Auto Get First Currciculum
  useEffect(() => {
    if (type != "favorite") {
      getDetailCurriculum(
        !!currInfoStorage?.id ? currInfoStorage?.id : curriculums?.data[0]?.id,
        !!currInfoStorage?.name
          ? currInfoStorage?.name
          : curriculums?.data[0]?.name || curriculums?.data[0]?.cat_name,
        !!currInfoStorage?.subType ? currInfoStorage?.subType : curriculums?.data[0]?.subType
      );
    }
  }, [curriculums?.data[0]]);

  useEffect(() => {
    if (type == "favorite") {
      dispatch({
        type: curriculumConstants.GET_LIST_CURRICULUM_FAVORITE_START,
      });
      // dispatch(teacherActions.getCurriculumFavorite(authentication.id));
      teacherActions
        .getCurriculumFavoriteV2(
          authentication.id,
          configConstants.DEFAULT_LIMIT,
          0
        )
        .then((curriculums) => {
          dispatch({
            type: curriculumConstants.GET_LIST_CURRICULUM_FAVORITE,
            curriculums,
          });
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, []);

  // Handle Check Duplicate

  var keySet = new Set(dataExercise.map((item) => item["lesson_id"]));

  const handleCheckDuplicateExercise = () => {
    for (var i = 0; i < exercises.data_exercise.length; i++) {
      if (
        keySet.has(exercises.data_exercise[i]["lesson_id"]) ||
        keySet.has(exercises.data_exercise[i]["exercise_id"])
      ) {
        return true; // Duplicates found
      }
    }
    return false; // No duplicates found
  };

  // Handle Go Back
  const handleGoBack = () => {
    localStorage?.removeItem("curriculum_info");
    if (
      [TypeAssignExercise.CAPACITY, TypeAssignExercise.DEMAND].includes(
        typeAssign
      )
    ) {
      localStorage.setItem("typeCurrExercise", "sunday");
      switch (typeAssign) {
        case "assign_freedom":
          dispatch({
            type: teacherConstants.CLEAR_ONLY_DATA_EXERCISES,
          });
          history.push(
            "/" +
              authentication.role +
              "/class/view/" +
              id +
              "/exercise/student/selection_assign"
          );
          break;
        case TypeAssignExercise.DEMAND:
        case TypeAssignExercise.CAPACITY:
          // const dataExercise = dataAssignProposal.reduce(function (
          //   accumulator,
          //   obj
          // ) {
          //   return accumulator.concat(obj.exercise_suggest);
          // },
          // []);

          // dispatch({
          //   type: teacherConstants.ADD_DATA_EXERCISE,
          //   data: {
          //     ...exercises,
          //     data_exercise: dataExercise,
          //   },
          // });

          history.push(
            "/" +
              authentication.role +
              "/class/view/" +
              id +
              "/exercise/student/" +
              typeAssign +
              "/list_proposals",

            { userIDSelected: userID }
          );
          break;
        default:
          dispatch({
            type: teacherConstants.CLEAR_ONLY_DATA_EXERCISES,
          });
          history.push(
            "/" +
              authentication.role +
              "/class/view/" +
              id +
              "/exercise/student"
          );
      }
    } else {
      localStorage.setItem("typeCurrExercise", "sunday");
      typeAssign == "assign_freedom"
        ? history.push(
            "/" +
              authentication.role +
              "/class/view/" +
              id +
              "/exercise/student/selection_assign"
          )
        : [TypeAssignExercise.DEMAND, TypeAssignExercise.CAPACITY].includes(
            typeAssign
          )
        ? history.push(
            "/" +
              authentication.role +
              "/class/view/" +
              id +
              "/exercise/student/" +
              typeAssign +
              "/list_proposals"
          )
        : id &&
          fromPage == "morepage" &&
          authentication.role == userConstants.ROLE_TEACHER
        ? history.push(
            "/" +
              authentication.role +
              "/class/view/" +
              id +
              `/exercise/review?${!isEmpty(fromPage) ? `page=${fromPage}` : ""}`
          )
        : history.push(
            "/" +
              authentication.role +
              "/class/view/" +
              id +
              `/exercise/student?${
                !isEmpty(fromPage) ? `page=${fromPage}` : ""
              }`
          );
    }
  };

  // Handle Confirm Back
  const handleConfirmBack = () => {
    setOpenPopUpBack(false);
    localStorage.setItem("typeCurrExercise", "sunday");
    switch (typeAssign) {
      case "assign_freedom":
        dispatch({
          type: teacherConstants.CLEAR_ONLY_DATA_EXERCISES,
        });
        history.push(
          "/" +
            authentication.role +
            "/class/view/" +
            id +
            "/exercise/student/selection_assign"
        );
        break;
      case TypeAssignExercise.DEMAND:
      case TypeAssignExercise.CAPACITY:
        const dataExercise = dataAssignProposal.reduce(function (
          accumulator,
          obj
        ) {
          return accumulator.concat(obj.exercise_suggest);
        },
        []);

        dispatch({
          type: teacherConstants.ADD_DATA_EXERCISE,
          data: {
            ...exercises,
            data_exercise: dataExercise,
          },
        });

        history.push(
          "/" +
            authentication.role +
            "/class/view/" +
            id +
            "/exercise/student/" +
            typeAssign +
            "/list_proposals"
        );
        break;
      default:
        dispatch({
          type: teacherConstants.CLEAR_ONLY_DATA_EXERCISES,
        });
        history.push(
          "/" + authentication.role + "/class/view/" + id + "/exercise/student"
        );
    }
  };

  // Handle Scroll Favorite
  const handleScrollFavorite = (e) => {
    if (
      e &&
      e.target?.scrollTop + e.target?.clientHeight + 5 >=
        e.target?.scrollHeight &&
      !isLoading &&
      isLoadMore
    ) {
      fetchDataCurriculumFavorite(offset + configConstants.DEFAULT_LIMIT);
    }
  };

  const handleDetailUnit = (e, data) => {
    if (data?.coming_soon == 1) {
      e?.preventDefault();
      dispatch(alertActions.error({
        message: 'Coming soon!',
        screen: 'ADD_CURRICULUM',
        isShowPopup: true
      }))
      return false;
    }
    return true;
  }
  
  return (
    <div className='sunE-right-container'>
      {Popup.showFilterCurriculum && (
        <PopUpFilter
          filter_curriculum={true}
          onClickNo={() => setcloseFilter()}
          width={420}
          class_id={id}
          fromPage={fromPage}
        />
      )}

      <Header
        title={"Chọn giáo trình"}
        component={type == "sunday" && componentFilter()}
        isBack
        clickBack={() => handleGoBack()}
      />
      <div className='sunE-container-box giaotrinh unit teacher-giaobai'>
        <div className='gtcn-menu-gr'>
          <span
            className={type === "sunday" ? " active" : ""}
            onClick={() => changeType("sunday")}
          >
            Sunday English
          </span>
          <span
            className={type === "personal" ? " active" : ""}
            onClick={() => changeType("personal")}
          >
            Cá nhân
          </span>
          <span
            className={type === "favorite" ? " active" : ""}
            onClick={() => changeType("favorite")}
          >
            Yêu thích
          </span>
        </div>
        {type !== "favorite" ? (
          <div className='flex-m'>
            <div
              className='flex-1 sunE-giaotrinh-list sunE-giaotrinh-list-teacher curriculum pr-25 scrollbar-custom'
              style={{ overflowX: "hidden" }}
            >
              {!isLoading && curriculums.data.map((data, i) => {
                return (
                  <div
                    className={
                      "box-giaotrinh-gr rel box-shadow" +
                      (curriculumId == data.id ? " active" : "")
                    }
                    onClick={() => {
                      getDetailCurriculum(
                        data.id,
                        data.name ? data.name : data.cat_name,
                        data?.sub_type
                      )
                    }}
                    key={i}
                  >
                    <div className='thumb'>
                      <img
                        src={
                          data?.type === classesConstants.SUNDAY ||
                          data?.type_class === classesConstants.SUNDAY_FREE
                            ? "/assets/images/logo_S.png"
                            : "/assets/images/giaotrinh/ico_gt.png"
                        }
                        alt='ico_gt_sun'
                      />
                    </div>
                    <div className='box-select-info'>
                      <p className='two-line'>{data.name}</p>
                    </div>
                  </div>
                );
              })}
              {!isLoading && curriculums.data.length === 0 && (
                <div className='__text_empty_curiclum_personal'>
                  Bạn chưa có giáo trình cá nhân nào. Hãy Tạo giáo trình cá nhân
                  của mình!
                </div>
              )}
            </div>
            {!isLoading && !isEmpty(curriculums.data) && (
              <div className='flex-1 pl-25 scrollbar-custom unit-list uk-container'>
                {!isEmpty(curriculums.detail.data_lesson.unit_name) && (
                  <div className='uk-grid'>
                    {!isLoadingDetail &&
                      curriculums.detail.data_lesson.unit_name.map(
                        (data, i) => {
                          return (
                            <div className=' uk-width-1-1' key={i}>
                              <div
                                className={
                                  "unit-giaotrinh-content" +
                                  (unitIdSelected === data.unit_id
                                    ? " active"
                                    : "")
                                }
                                key={i}
                              >
                                <div
                                  className='box-giaotrinh-gr rel box-shadow'
                                  onClick={(e) => {
                                    if (!handleDetailUnit(e, data)) {
                                      return;
                                    };
                                    // if (!!subType) {
                                      handleGetListSkill(e, data)
                                    // } else { 
                                    //   unitIdSelected === data.unit_id
                                    //   ? setUnitIdSelected("")
                                    //   : setUnitIdSelected(data.unit_id)
                                    // }
                                  }}
                                >
                                  <div className='box-unit-info'>
                                    <p>
                                      <span className='bold one-line'>
                                        {data.stt ? `${data.stt}: ` : null}{" "}
                                        {data.unit_name}
                                      </span>
                                    </p>
                                  </div>
                                  {unitIdSelected === data.unit_id ? (
                                    <div
                                      className='btn-more-info center-flex'
                                      onClick={() => setUnitIdSelected("")}
                                    >
                                      <img
                                        src='/assets/images/giaotrinh/ico_show_.png'
                                        alt='-'
                                        className='show_img'
                                      />
                                    </div>
                                  ) : (
                                    <div
                                      className='btn-more-info center-flex'
                                      onClick={() => {
                                        if (!handleDetailUnit(e, data)) {
                                          return;
                                        };
                                        setUnitIdSelected(data.unit_id)
                                      }
                                      }
                                    >
                                      <img
                                        src='/assets/images/giaotrinh/ico_add_black.png'
                                        alt='+'
                                      />
                                    </div>
                                  )}
                                </div>
                                {!subType && data.list_skill.map((dataSkill, i) => {
                                  return (
                                    <Link
                                      to={
                                        "/" +
                                        authentication.role +
                                        "/class/curriculum/view/detail/" +
                                        curriculumId +
                                        "/list_skill/" +
                                        data.unit_id +
                                        "/list_exercise/" +
                                        dataSkill.skill +
                                        "?assign=" +
                                        typeAssign +
                                        "&user_id=" +
                                        userID +
                                        '&stt=' + data?.stt +
                                        (!isEmpty(fromPage)
                                          ? `&page=${fromPage}`
                                          : "") +
                                        (!isEmpty(curriculumId)
                                          ? `&curriculum_id=${curriculumId}`
                                          : "")
                                      }
                                      key={i}
                                      onClick={() => {
                                        dispatch({
                                          type: curriculumConstants.GET_LESSON_IN_SKILL,
                                          payload:
                                            dataSkill?.lesson_in_skill?.map(
                                              (item) => ({
                                                ...item,
                                                total_time: item?.total_time
                                                  ? Number(item?.total_time)
                                                  : undefined,
                                              })
                                            ) || [],
                                        });
                                      }}
                                    >
                                      <div className='unit-more-info'>
                                        <div className='flex-m unit-more-info-item'>
                                          <div className='flex-1 info-item-desc'>
                                            <h2 className='text-center'>
                                              {dataSkill.skill_name
                                                ? dataSkill.skill_name.capitalize()
                                                : dataSkill?.skill
                                                    ?.capitalize()
                                                    ?.replace("_", " ")}
                                            </h2>
                                          </div>
                                        </div>
                                      </div>
                                    </Link>
                                  );
                                })}
                                {!!subType && !!data?.list_topic?.length && data?.list_topic.map((dataTopic, i) => {
                                  return (
                                    <Link
                                    to={
                                      "/" +
                                      authentication.role +
                                      "/class/curriculum/view/detail/" +
                                      curriculumId +
                                      "/list_skill/" +
                                      dataTopic.unit_id +
                                      "/list_exercise/" +
                                      dataTopic?.topic_name + `?subType=${subType}` +
                                      "&assign=" +
                                      typeAssign +
                                      "&user_id=" +
                                      userID +
                                      // '&stt=' + data?.stt +
                                      (!isEmpty(fromPage)
                                        ? `&page=${fromPage}`
                                        : "") +
                                      (!isEmpty(curriculumId)
                                        ? `&curriculum_id=${curriculumId}`
                                        : "") +
                                      (`&topicId=${dataTopic?.topic_id}`)
                                    }
                                      key={i}
                                    >
                                      <div className='unit-more-info'>
                                        <div className='flex-m unit-more-info-item'>
                                          <div className='flex-1 info-item-desc'>
                                            <h2 className='text-center'>
                                              {
                                                dataTopic?.topic_name?.replace("_", " ")?.capitalize()
                                              }
                                            </h2>
                                          </div>
                                        </div>
                                      </div>
                                    </Link>
                                  );
                                })}
                              </div>
                            </div>
                          );
                        }
                      )}
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          <>
            <ContentSkill
              typeAssign={typeAssign}
              userID={userID}
              fullWish={true}
              disableGetDetailCurriculumById={"disabled"}
              data={curriculums.data}
              textEmpty='Bạn chưa có bài tập yêu thích nào.'
              handleScrollFavorite={handleScrollFavorite}
              typeCurriculum='favorite'
            />
          </>
        )}
      </div>

      {openPopUpBack && (
        <PopUpYesNo
          onClickYes={() => handleConfirmBack()}
          onClickNo={() => setOpenPopUpBack(false)}
          labelYes={"Đồng ý"}
          labelNo={"Hủy"}
          message={[
            "Bạn có chắc chắn tiếp tục quay lại?",
            "Danh sách bài đã chọn sẽ không được lưu!",
          ]}
        />
      )}
      {alert.message && alert.screen === 'ADD_CURRICULUM' && 
        <Alert alert={alert} />
      }
    </div>
  );
}

export { AddCurriculumExercise };
