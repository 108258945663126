import React, { useEffect, useState } from "react";
import { curriculumActions, teacherActions } from "../../../_actions";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Header } from "../../../_components/Admin/Header";
import { ContentSkill } from "./";
import { useHistory } from "react-router-dom";
import { HAS_ESSAY, NO_ESSAY, teacherConstants } from "../../../_constants";
import { teacherService } from "../../../_services";
import { curriculumConstants } from "../../../_constants";
import { isEmpty } from "lodash";
import { apiCaller } from "../../../_helpers";
import LessonTopic from "./LessonTopic";
import PopupFilterLessonTopic from "../../../_components/Popup/PopupFilterLessonTopic";
import { Alert } from "../../../_components/Alert";
import moment from "moment";

function ViewDetailCurriculumListExercise() {
  const { id, unit_id, skill } = useParams();

  const history = useHistory();

  const search = history?.location?.search;
  const params = new URLSearchParams(search);
  const typeAssign = params?.get("assign");
  const userID = params?.get("user_id");
  const subType = params.get("subType");
  const topicId = params.get("topicId");
  const lessonSkill = useSelector((state) => state.curriculums.lesson_skill);
  const lessonTopic = useSelector((state) => state.curriculums.lesson_topic);
  const alert = useSelector((state) => state.alert);
  const [limit] = useState(10);
  const [offset, setOffset] = useState(0)
  const [isFiltered, setIsFiltered] = useState(false)
  const [isOpenModalFilter, setIsOpenModalFilter] = useState(false)
  const [isChangedFilter, setIsChangedFilter] = useState(false)
  const [dataFilter, setDataFilter] = useState({provinceId: '', year: '', typeEssay: ''})
  const [isLoadingLessonTopic, setIsLoadingLessonTopic] = useState(false);
  const [isHasMoreLessonTopic, setIsHasMoreLessonTopic] = useState(true);


  const fromPage = params?.get("page");
  // useEffect(() => {
  //   dispatch(teacherActions.getDetailCurriculumById(id));
  // }, []);
  const dispatch = useDispatch();
  const curriculums = useSelector(
    (state) => state.curriculums.detail.data_lesson.unit_name
  );
  let currentCurriculum = curriculums.find((item) => item.unit_id === unit_id);
  let currentListSkill;
  if (!isEmpty(lessonSkill?.data)) {
    currentListSkill = lessonSkill.data?.find(
      (item) => item.skill == skill
    )?.list_lesson;
  } else if (!isEmpty(currentCurriculum?.list_skill)) {
    currentListSkill = currentCurriculum.list_skill?.find(
      (item) => item.skill === skill
    ).lesson_in_skill;
  }
  const exercises = useSelector((state) => state.classes.exercises);
  const [saveListExercise, setSaveListExercise] = useState(
    exercises.data_exercise
  );
  const persistCurriculumTeacher = useSelector((state) => state.persistCurriculumTeacher);

  const getLessonTopic = () => {
    setIsLoadingLessonTopic(true)
    teacherService.getLessonByTopicTeacher({
      curriculumId: id,
      topicId: topicId,
      unitId: unit_id,
      offset,
      limit,
      addressId: dataFilter?.provinceId,
      essay: dataFilter?.typeEssay === HAS_ESSAY ? 1 : dataFilter?.typeEssay === NO_ESSAY ? 0 : dataFilter?.typeEssay,
      year: dataFilter?.year
    }).then((res) => {
      if (res) {
        dispatch({
          type: curriculumConstants.LESSON_BY_TOPIC_TEACHER,
          lesson_topic: res,
        });
        if (res?.lesson?.length < limit) {
          setIsHasMoreLessonTopic(false);
        }
      }
    }).finally(() => {
      setIsLoadingLessonTopic(false)
    })
  }

  const getNextLessonTopic = () => {
    if (isLoadingLessonTopic || !isHasMoreLessonTopic) {
      return;
    }
    setIsLoadingLessonTopic(true);
    teacherService.getLessonByTopicTeacher({
      curriculumId: id,
      topicId: topicId,
      unitId: unit_id,
      offset: offset + limit,
      limit,
      addressId: dataFilter?.provinceId,
      essay: dataFilter?.typeEssay === HAS_ESSAY ? 1 : dataFilter?.typeEssay === NO_ESSAY ? 0 : dataFilter?.typeEssay,
      year: dataFilter?.year
    }).then((res) => {
      if (res) {
        dispatch({
          type: curriculumConstants.LESSON_BY_TOPIC_TEACHER,
          lesson_topic: {...res,
            lesson: [
              ...lessonTopic?.lesson,
              ...res?.lesson
            ]
          },
        });
        setOffset(offset + limit)
        setIsLoadingLessonTopic(false);
        if (res?.lesson?.length < limit) {
          setIsHasMoreLessonTopic(false);
        }
      }
    }).catch((err) => {
      setIsHasMoreLessonTopic(false);
      setIsLoadingLessonTopic(false);
    })
  }

  const handleFilterLessonTopic = (typeEssay, provinceId, year) => {
    if (!!typeEssay || !!provinceId || !!year) {
      setIsFiltered(true);
    }
    setDataFilter({
      provinceId,
      typeEssay,
      year
    })
    setOffset(0);
    setIsChangedFilter(true);
    setIsHasMoreLessonTopic(true);
  }

  useEffect(() => {
    exercises.data_exercise = saveListExercise;
    dispatch({ type: teacherConstants.ADD_DATA_EXERCISE, data: exercises });
  }, [saveListExercise]);

  useEffect(() => {
    if (!!subType) {
      getLessonTopic()
    } else {
      getData()
    }
  }, []);

  useEffect(() => {
    if(isChangedFilter) {
      getLessonTopic();
      setIsChangedFilter(false)
    }
  }, [isChangedFilter])

  const getData = (isRefresh = false) => {
    // console.log("=====c1")
    // if (fromPage != "curriculum") {
      const dataUnit = persistCurriculumTeacher?.dataUnitByCurriculum?.[currentCurriculum?.curriculum_id]?.[unit_id];
      // if (!!dataUnit?.data?.length && !isRefresh && !!dataUnit?.saveAt && moment(new Date()).diff(moment(dataUnit?.saveAt), 'hours') < 6) {
      //   dispatch({
      //     type: curriculumConstants.LESSON_BY_SKILL_TEACHER,
      //     lesson_skill: {
      //       data: dataUnit?.data,
      //       base_url: dataUnit?.base_url
      //     },
      //   });
      // } else {
        curriculumActions.getDataUserInUnit(unit_id).then(resDataUser => {
          curriculumActions.getLessonByUnit(unit_id).then((res) => {
              const listSkill = res?.skill_list?.map(item => {
                const dataUser = resDataUser?.skill_list?.find(data => data?.skill === item?.skill)
                return {
                  ...item,
                  list_lesson: item?.list_lesson?.map(lesson => {
                    return {
                      ...lesson,
                      unit_id,
                      unit_name: currentCurriculum?.unit_name,
                      curriculum_id: currentCurriculum?.curriculum_id,
                      curriculum_name: currentCurriculum?.curriculum_name,
                      is_lock_lesson: dataUser?.is_lock_lesson
                    }
                  })
                }
              })
              dispatch({
                type: curriculumConstants.LESSON_BY_SKILL_TEACHER,
                lesson_skill: {
                  data: listSkill,
                  base_url: res?.base_url
                },
              });
              dispatch({
                type: curriculumConstants.SAVE_DETAIL_UNIT_TEACHER,
                payload: {
                  data: listSkill,
                  base_url: res?.base_url,
                  curriculumId: currentCurriculum?.curriculum_id,
                  unitId: unit_id
                },
              });
          });
        })
      // }
    // }
  }

  function componentFilter() {
    if (!subType || currentCurriculum?.list_topic?.find(item => item?.id == topicId)?.full_exam != 1) {
      return;
    }
    return (
      <>
        <div className="refresh-curriculum-btn-teacher-header" style={{marginRight: 12}} onClick={() => {
          if (!!subType) {
            getLessonTopic()
          } else {
            getData(true)
          }
        }}>
          <img src="/assets/images/icon_refresh_curriculum.png" style={{width: '100%', height: '100%', borderRadius: '50%'}}/>
        </div>
        <div
          className='form-sunE-button btn-filter rel'
          onClick={() => setIsOpenModalFilter(true)}
        >
          <img
            src={
              isFiltered
                ? `/assets/images/teacher/ico_filter.png`
                : `/assets/images/teacher/icon_not_yet_filter.png`
            }
            alt='ico_add'
            className='ml--5'
            style={{cursor: 'pointer'}}
          />
          <button className='btn-line-blue'>Lọc</button>
        </div>
      </>
    );
  }

  const titleHeader = skill.charAt(0).toUpperCase() + skill.slice(1);

  return (
    <div className='sunE-right-container'>
      <Header title={decodeURIComponent(titleHeader?.capitalize()?.replace("_", " "))} component={componentFilter()} isBack />
      <div className='sunE-container-box giaotrinh filter flex-column'>
        {currentListSkill && !subType && (
          <ContentSkill
            data={currentListSkill}
            textEmpty='Bạn chưa có bài tập yêu thích nào.'
            typeAssign={typeAssign}
            userID={userID}
            setSaveListExercise={setSaveListExercise}
          />
        )}
        {!!subType && (
          <LessonTopic
            data={lessonTopic?.lesson}
            curriculumId={id}
            topicId={topicId}
            unitId={unit_id}
            limit={limit}
            offset={offset}
            setOffset={setOffset}
            textEmpty='Chưa có bài nào.'
            typeAssign={typeAssign}
            userID={userID}
            setSaveListExercise={setSaveListExercise}
            fetchMoreLesson={getNextLessonTopic}
            isLoading={isLoadingLessonTopic}
          />
        )}
      </div>
      <PopupFilterLessonTopic 
        open={isOpenModalFilter} 
        onClose={() => setIsOpenModalFilter(false)} 
        onSubmit={(type, province, year) => handleFilterLessonTopic(type, province, year)}
        typeEssayFilter={dataFilter?.typeEssay}
        provinceIdFilter={dataFilter?.provinceId}
        yearFilter={dataFilter?.year}
        isFiltered={isFiltered}
        setIsFiltered={setIsFiltered}
      />
      {alert.message && 
        <Alert alert={alert} />
      }
    </div>
  );
}

export { ViewDetailCurriculumListExercise };
