import React, { useState, Fragment, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import "../../../_containers/StudentPage/style.scss";
import { smoothScroll } from "../../../_base/animsScroll";
import $ from "jquery";
import LazyLoad from "react-lazyload";
import { apiCaller, history } from "../../../_helpers";
import { isEmpty } from "lodash";
import { configConstants, curriculumConstants } from "../../../_constants";
import { hasScroll } from "../../../_helpers/utils";
import { alertActions, curriculumActions } from "../../../_actions";
import { Alert } from "../../Alert";
import { teacherService } from "../../../_services";
import moment from "moment";

function ShowMapGrid(props) {
  const { view, curriculums } = props;
  const authentication = useSelector((state) => state.authentication);
  const alert = useSelector((state) => state.alert);

  const { id } = useParams();
  const [unitIdSelected, setnitIdSelected] = useState(false);

  // Define the amount to scroll by when the buttons are clicked
  const scrollAmount = 750;
  const animationDuration = 300;
  const [canPrev, setCanPrev] = useState(false);
  const [canNext, setCanNext] = useState(true);
  const search = history?.location?.search;
  const params = new URLSearchParams(search);
  const fromPage = params?.get("page");
  const subType = params.get("subType");

  const [contentMapCurriculum, setContentMapCurriculum] = useState(
    document.querySelector("#sunE_map_curriculum")
  );
  const [hasScrollMap, setHasScrollMap] = useState(false)
  const dispatch = useDispatch();
  const persistCurriculumTeacher = useSelector((state) => state.persistCurriculumTeacher);

  useEffect(() => {
    const containerMap = document.querySelector("#sunE_map_curriculum")
    setContentMapCurriculum(containerMap);
    setHasScrollMap(hasScroll(containerMap, 'horizontal'))
  }, []);

  useEffect(() => {
    $(document).ready(function ($) {
      var down = false;
      var scrollLeft = 0;
      var x = 0;

      $(".sunE-content-unit")
        .mousedown(function (e) {
          down = true;
          scrollLeft = this.scrollLeft;
          x = e.clientX;
        })
        .mouseup(function () {
          down = false;
        })
        .mousemove(function (e) {
          var maxScroll = this.scrollWidth - this.clientWidth;

          if (down) {
            this.scrollLeft = scrollLeft + x - e.clientX;
            if (this.scrollLeft == 0) {
              setCanPrev(false);
            } else {
              setCanPrev(true);
              if (this.scrollLeft >= maxScroll) {
                setCanNext(false);
              } else {
                setCanNext(true);
              }
            }
          }
        })
        .mouseleave(function () {
          down = false;
        });
    });
  }, []);

  // Handlle Click Btn Move Map
  const handleClickBtnMap = (type) => {
    switch (type) {
      case "prev":
        if (contentMapCurriculum.scrollLeft > 0) {
          setCanNext(true);
          smoothScroll(contentMapCurriculum, -scrollAmount, animationDuration);
          if (contentMapCurriculum?.scrollLeft - scrollAmount <= 0) {
            setCanPrev(false);
          }
        } else {
          setCanPrev(false);
        }
        break;
      case "next":
        if (
          contentMapCurriculum?.scrollLeft + contentMapCurriculum.clientWidth <
          contentMapCurriculum.scrollWidth
        ) {
          setCanPrev(true);
          smoothScroll(contentMapCurriculum, scrollAmount, animationDuration);
          if (
            contentMapCurriculum?.scrollLeft +
              contentMapCurriculum.clientWidth +
              scrollAmount >=
            contentMapCurriculum.scrollWidth
          ) {
            setCanNext(false);
          }
        } else {
          setCanNext(false);
        }
        break;
      default:
    }
  };

  const upperFirstCase = useCallback(
    (actionName) => {
      if (!actionName) {
        return "";
      }
      return actionName.charAt(0).toUpperCase() + actionName.slice(1);
    },
    [curriculums]
  );

  const handleGetListSkill = (detailUnit) => {
    if (unitIdSelected === detailUnit.unit_id) {
      setnitIdSelected(false)
      return;
    }
    if (!!detailUnit?.list_skill?.length) {
      setnitIdSelected(detailUnit?.unit_id);
      return;
    }
    if (!subType) {
      const dataUnit = persistCurriculumTeacher?.dataUnitByCurriculum?.[id]?.[detailUnit.unit_id];
      if (!!dataUnit?.data?.length && !!dataUnit?.saveAt && moment(new Date()).diff(moment(dataUnit?.saveAt), 'hours') < 6) {
        dispatch({
          type: curriculumConstants.LESSON_BY_SKILL_TEACHER,
          lesson_skill: {
            data: dataUnit?.data,
            base_url: dataUnit?.base_url
          },
        });
        const listSkill = dataUnit?.data;
        const curriculum = {
          ...curriculums.detail,
          data_lesson: {
            ...curriculums.detail.data_lesson,
            unit_name: curriculums.detail.data_lesson?.unit_name?.map(item => {
              if (item?.unit_id == detailUnit?.unit_id) {
                return {
                  ...item,
                  list_skill: listSkill
                }
              }
              return item;
            })
          },
        }
        dispatch({
          type: curriculumConstants.GET_DETAIL_CURRICULUM,
          curriculum,
        });
        setnitIdSelected(detailUnit.unit_id)
      } else {
        curriculumActions.getLessonByUnit(detailUnit.unit_id).then((res) => {
          const skill_list = res?.skill_list?.map(item => {
            return {
              ...item,
              list_lesson: item?.list_lesson?.map(lesson => {
                return {
                  ...lesson,
                  unit_id: detailUnit.unit_id,
                  unit_name: detailUnit?.unit_name,
                  curriculum_id: detailUnit?.curriculum_id,
                  curriculum_name: detailUnit?.curriculum_name
                }
              })
            }
          })
          dispatch({
            type: curriculumConstants.LESSON_BY_SKILL_TEACHER,
            lesson_skill: {
              data: skill_list,
              base_url: res?.base_url
            },
          });
          dispatch({
            type: curriculumConstants.SAVE_DETAIL_UNIT_TEACHER,
            payload: {
              data: skill_list,
              base_url: res?.base_url,
              curriculumId: detailUnit?.curriculum_id,
              unitId: detailUnit.unit_id
            },
          });
          const listSkill = res?.skill_list;
          const curriculum = {
            ...curriculums.detail,
            data_lesson: {
              ...curriculums.detail.data_lesson,
              unit_name: curriculums.detail.data_lesson?.unit_name?.map(item => {
                if (item?.unit_id == detailUnit?.unit_id) {
                  return {
                    ...item,
                    list_skill: listSkill
                  }
                }
                return item;
              })
            },
          }
          dispatch({
            type: curriculumConstants.GET_DETAIL_CURRICULUM,
            curriculum,
          });
        }).finally(() => {
          setnitIdSelected(detailUnit.unit_id)
        });
        }
      return;
    }
    if (detailUnit?.has_compostition === true) {
      apiCaller('/api_preparation_course/composition?unit_id=' + detailUnit?.unit_id + "&curriculum_id=" + detailUnit?.curriculum_id, "GET").then((res) => {
        dispatch({
          type: curriculumConstants.GET_DETAIL_CURRICULUM,
          curriculum: {
            ...curriculums?.detail,
            ...curriculums?.detail,
            data_lesson: {
              ...curriculums?.detail?.data_lesson,
              unit_name: curriculums?.detail?.data_lesson?.unit_name?.map(item => ({
                ...item,
                list_topic: [],
                list_composition: res?.data
              }))
            }
          },
        });
      }).finally(() => {
        setnitIdSelected(detailUnit.unit_id)
      })
    } else if (detailUnit?.has_compostition === false) {
      apiCaller(
        `/api_preparation_course/list_topic?unit_id=${detailUnit?.unit_id}&curriculum_id=${detailUnit?.curriculum_id}`, "GET").then((res) => {
        dispatch({
          type: curriculumConstants.GET_DETAIL_CURRICULUM,
          curriculum: {
            ...curriculums?.detail,
            data_lesson: {
              ...curriculums?.detail?.data_lesson,
              unit_name: curriculums?.detail?.data_lesson?.unit_name?.map(item => ({
                ...item,
                list_topic: res?.data?.list_topic,
                list_composition: []
              }))
            }
          },
        });
      }).finally(() => {
        setnitIdSelected(detailUnit.unit_id)
      })
    }
  }

  const handleDetailUnit = (e, data) => {
    if (data?.coming_soon == 1) {
      e.preventDefault();
      dispatch(alertActions.error({
        message: 'Coming soon!',
        screen: 'MAP_CURRICULUM',
        isShowPopup: true
      }))
      return false;
    }
    return true;
  }

  const getLessonInSkill = (e, skill, link) => {
    // if (!!skill?.lesson_in_skill?.length) {
    //   return;
    // };
    // e.preventDefault();
    // teacherService.getLessonBySkillTeacher(skill?.unit_id).then((res) => {
    //   if (res) {
    //     dispatch({
    //       type: curriculumConstants.LESSON_BY_SKILL_TEACHER,
    //       lesson_skill: res,
    //     });
    //     history.push(link)
    //   }
    // });
  }

  return (
    <div className="sunE-container-box giaotrinh unit  sunE_map_curriculum">
      {view === "map" && (
        <>
          <div
            id="sunE_map_curriculum"
            className="sunE-content-unit scrollbar-custom"
          >
            <div className="sunE-unit-list">
              {curriculums.detail.data_lesson.unit_name.map((data, i) => {
                let avatar = "/assets/images/giaotrinh/avt_default_unit.png";
                if (data.unit_avatar) {
                  avatar = configConstants.BASE_URL + data?.unit_avatar;
                }
                const linkTo = data?.has_compostition ? ("/" +
                  authentication.role +
                  "/curriculum/view/detail/" +
                  id +
                  "/list_skill/" +
                  data.unit_id + '?' +
                  (!isEmpty(fromPage) ? `page=${fromPage}` : "") +
                  (!!subType ? `&subType=${subType}` : '') + 
                  '&isComposition=true'
                ) : (
                  "/" +
                  authentication.role +
                  "/curriculum/view/detail/" +
                  id +
                  "/list_skill/" +
                  data.unit_id + '?' +
                  (!isEmpty(fromPage) ? `page=${fromPage}` : "") +
                  (!!subType ? `&subType=${subType}` : '')
                )
                return (
                  <div
                    className="flex-column sunE-unit-item"
                    key={i}
                    style={{ position: "relative" }}
                  >
                    {i % 2 === 0 ? (
                      <Fragment>
                        <Link
                          to={
                            linkTo
                          }
                          onClick={(e) => handleDetailUnit(e, data)}
                          style={{ zIndex: 10 }}
                        >
                          <div className="flex-1 cs-point rel">
                            <h2>{data?.stt}</h2>
                            <p className="two-line">{data?.unit_name}</p>
                            <div once className="img-bg-x">
                              <img draggable = "false" className="__avt_unit" src={avatar} />
                            </div>
                            {data?.coming_soon == 1 ? <div className='pink-ab'>
                              <img
                                src='/assets/images/student/ico_lock_pink.png'
                                alt='ico_lock_pink'
                              />
                            </div> : null}
                          </div>
                        </Link>
                        <div className="flex-1">
                          <p className="h-287">&nbsp;</p>
                        </div>
                        {curriculums.detail.data_lesson.unit_name.length - 1 !==
                          i && (
                          <img
                            className="__way_map"
                            src="/assets/images/icon/ico_dash_connect_2.png"
                          />
                        )}
                      </Fragment>
                    ) : (
                      <Fragment>
                        <div className="flex-1">
                          <p className="h-287">&nbsp;</p>
                        </div>
                        <Link
                          to={
                            linkTo
                          }
                          onClick={(e) => handleDetailUnit(e, data)}
                          style={{ zIndex: 10 }}
                        >
                          <div className="flex-1 cs-point rel">
                            <h2>{data?.stt}</h2>
                            <p className="two-line">{data.unit_name}</p>
                            <div className="img-bg-x">
                              <img draggable = "false" className="__avt_unit" src={avatar} />
                            </div>
                            {data?.coming_soon == 1 ? <div className='pink-ab'>
                              <img
                                src='/assets/images/student/ico_lock_pink.png'
                                alt='ico_lock_pink'
                              />
                            </div> : null}
                          </div>
                        </Link>
                        {curriculums.detail.data_lesson.unit_name.length - 1 !==
                          i && (
                          <img
                            className="__way_map_down"
                            src="/assets/images/icon/ico_dash_connect_1.png"
                          />
                        )}
                      </Fragment>
                    )}
                  </div>
                );
              })}
            </div>
          </div>

          {hasScrollMap && <div className="footer_curriculum flex-center pd-top-4-pc">
            <button
              className="btn btn-primary"
              onClick={() => handleClickBtnMap("prev")}
              disabled={!canPrev}
            >
              <img src="/assets/images/icon/ico_left_white.png" />
            </button>
            <button
              className="btn btn-primary"
              onClick={() => handleClickBtnMap("next")}
              disabled={!canNext}
            >
              <img src="/assets/images/icon/ico_right_white.png" />
            </button>
          </div>}
        </>
      )}
      {view === "grid" && (
        <div className="flex-2 pl-25 scrollbar-custom unit-list uk-container">
          <div className="uk-grid">
            {curriculums.detail.data_lesson.unit_name.map((data, i) => {
              return (
                <div className=" uk-width-1-1" key={i}>
                  <div
                    className={
                      "unit-giaotrinh-content" +
                      (unitIdSelected === data.unit_id ? " active" : "")
                    }
                    key={i}
                  >
                    <div
                      className="box-giaotrinh-gr rel box-shadow"
                      onClick={(e) => {
                        if (!handleDetailUnit(e, data)) {
                          return;
                        };
                        // if (!!subType) {
                          handleGetListSkill(data)
                        // } else {
                        //   setnitIdSelected(
                        //     unitIdSelected === data.unit_id ? false : data.unit_id
                        //   )
                        // }
                      }}
                    >
                      <div className="box-unit-info">
                        <p>
                          <span className="bold">
                            {_.isEmpty(data.stt) ? "" : (data.stt + ": ")}
                            {data.unit_name}
                          </span>
                        </p>
                      </div>
                      <div
                        className="btn-more-info center-flex"
                        onClick={() => setnitIdSelected(false)}
                      >
                        <img
                          src={`/assets/images/giaotrinh/${
                            unitIdSelected === data.unit_id
                              ? "ico_show_"
                              : "ico_add_black"
                          }.png`}
                          alt="-"
                          className="show_img"
                        />
                      </div>
                    </div>
                    {!subType && data.list_skill.map((dataSkill, i) => {
                      const link = "/" +
                            authentication.role +
                            "/curriculum/view/detail/" +
                            id +
                            "/list_skill/" +
                            data.unit_id +
                            "/list_exercise/" +
                            dataSkill.skill + '?' +
                            (!isEmpty(fromPage) ? `page=${fromPage}` : "") +
                            (!!subType ? `&subType=${subType}` : '');
                      return (
                        <Link
                          to={
                            link
                          }
                          onClick={(e) => getLessonInSkill(e, data, link)}
                          key={i}
                        >
                          <div className="unit-more-info">
                            <div className="flex-m unit-more-info-item">
                              <div className="flex-1 info-item-desc">
                                <h2 className="text-center">
                                  {upperFirstCase(
                                    dataSkill?.skill_name
                                      ? dataSkill.skill_name
                                      : dataSkill?.skill
                                          ?.replace("_", " ")
                                          ?.capitalize()
                                  )}
                                </h2>
                              </div>
                            </div>
                          </div>
                        </Link>
                      );
                    })}
                    {!!subType && !!data?.list_topic?.length && data?.list_topic.map((dataTopic, i) => {
                      return (
                        <Link
                          to={
                            "/" +
                            authentication.role +
                            "/curriculum/view/detail/" +
                            id +
                            "/list_skill/" +
                            data.unit_id +
                            "/list_exercise/" +
                            dataTopic?.topic_name + `?subType=${subType}` +
                            (!isEmpty(fromPage) ? `&page=${fromPage}` : "") + 
                            (`&topicId=${dataTopic?.topic_id}`)
                          }
                          key={i}
                        >
                          <div className="unit-more-info">
                            <div className="flex-m unit-more-info-item">
                              <div className="flex-1 info-item-desc">
                                <h2 className="text-center">
                                  {upperFirstCase(
                                    dataTopic?.topic_name?.replace("_", " ")?.capitalize()
                                  )}
                                </h2>
                              </div>
                            </div>
                          </div>
                        </Link>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {alert.message && alert.screen === 'MAP_CURRICULUM' && 
        <Alert alert={alert} />
      }
    </div>
  );
}

export { ShowMapGrid };
