import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { audioPlayerConstants } from "../../_constants/audioPlayer";

let timeoutId = null;
function useAudioPlayerV2(id) {
  const [duration, setDuration] = useState(0);
  const [curTime, setCurTime] = useState(0);
  // const [playing, setPlaying] = useState(false);
  const [clickedTime, setClickedTime] = useState(0);
  const audioPlayer = useSelector(state => state.audioPlayer.audioPlayer)
  const dispatch = useDispatch()

  const setPlaying = (isPlaying) => {
    dispatch({
      type: audioPlayerConstants.UPDATE_AUDIO_PLAYER,
      payload: {
        [id]: isPlaying
      }
    })
  }
console.log('clickedTime: ', clickedTime)
  let audio = document.getElementById(id);
  useEffect(() => {
    audio = document.getElementById(id);
    if (audio.currentTime >= duration) {
      audio.currentTime = 0;
      setCurTime(0);
    }
    if (!audioPlayer?.[id]) {
      audio.pause();
      audio.removeEventListener("loadeddata", setAudioData);
      audio.removeEventListener("timeupdate", setAudioTime);
    } else {
      audio.play();
      audio.addEventListener("loadeddata", setAudioData);
      audio.addEventListener("timeupdate", setAudioTime);
    }
  }, [audioPlayer?.[id]]);

  useEffect(() => {
    if (parseInt(curTime) == parseInt(duration + 3)) {
      setPlaying(false);
    }
  }, [clickedTime, curTime]);

  // state setters wrappers
  const setAudioData = (e) => {
    setDuration(e.target.duration);
    setCurTime(e.target.currentTime);
  };

  const setAudioTime = (e) => {
    if (e.target.currentTime >= duration) {
      setTimeout(() => {
        setCurTime(0);
      }, 100)
    } else {
      setCurTime(e.target.currentTime);
    }
  };

  useEffect(() => {
    // DOM listeners: update React state on DOM events
    audio.addEventListener("loadeddata", setAudioData);

    audio.addEventListener("timeupdate", setAudioTime);

    // React state listeners: update DOM on React state changes
    audioPlayer?.[id] ? audio.play() : audio.pause();

    if (clickedTime && clickedTime !== curTime) {
      audio.currentTime = clickedTime;
      setClickedTime(null);
    }

    // effect cleanup
    return () => {
      audio.removeEventListener("loadeddata", setAudioData);
      audio.removeEventListener("timeupdate", setAudioTime);
    };
  });

  useEffect(() => {
    if (audioPlayer?.[id]) {
      let time =
        parseInt(duration) + 1 === parseInt(curTime)
          ? duration
          : duration - curTime;
      const audio = document.getElementById(id);
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        audio.pause();
        setPlaying(false);
      }, time * 1000);
    } else {
      // setCurTime(0)
    }
  }, [audioPlayer?.[id], curTime]);

  useEffect(() => {
    if (audioPlayer?.[id] === undefined) {
      dispatch({
        type: audioPlayerConstants.UPDATE_AUDIO_PLAYER,
        payload: {
          // [id]: false
        }
      })
    }
  }, [])

  return {
    curTime,
    duration,
    playing: !!audioPlayer?.[id],
    setPlaying,
    setClickedTime,
    setCurTime,
  };
}

export default useAudioPlayerV2;
