import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { userConstants } from "../../../_constants";
import LazyLoad from "react-lazyload";

function ListClass(props) {
  let { classes, base_url, handleScroll } = props;
  const authentication = useSelector((state) => state.authentication);

  return (
    <div
      className='sunE-class-list uk-container'
      onScroll={(e) => handleScroll(e)}
    >
      <LazyLoad once height={"80vh"} className='uk-grid flex flex-wrap' style={{marginLeft: -40}}>
        {classes.map((data, i) => {
          let link =
            authentication.role === userConstants.ROLE_TEACHER
              ? "/" + authentication.role
              : "/" + authentication.role + "/more";
          link +=
            "/class/view/" +
            (data?.class_id ? data?.class_id : data?.id) +
            (data?.type === "offline" ? "/offline" : "");
          return (
            data && (
              <div className={`uk-width-1-2 ${data?.type} sunE-class-item`} key={i}>
                <div className='class-box box-shadow'>
                  <Link to={link}>
                    <div className='flex-m' style={{ height: "100%" }}>
                      <div className='class-box-img'>
                        <img
                          className='img-cover'
                          src={base_url + data?.class_avatar}
                          alt='avatar'
                        />
                      </div>
                      <div className='flex-1 class-box-desc flex-column'>
                        <h2 className='title-line-blue two-line'>
                          {data?.class_name}
                        </h2>
                        <div className='flex-1 content-flex-end'>
                          <div>
                            <p className='two-line'>
                              {data?.organization_name}
                            </p>
                            <span>
                              Lớp{" "}
                              <span className={"class-" + data?.type}>
                                {" "}
                                {data?.type}
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            )
          );
        })}
      </LazyLoad>
    </div>
  );
}

export { ListClass };
