import React from "react";
import "./styles.scss";
import { CheckBoxCustom, renderStatusCheckBox } from "./CheckBoxCustom";
import { isEmpty } from "lodash";
import { renderTextAnswerReview } from ".";
import ReviewLaterBox from "./ReviewLaterBox";
import { convertSingleQuote, validWord } from "../../_helpers/utils";

export const ExamWriting = (props) => {
  const {
    type,
    title,
    category,
    indexQuestion,
    resultExam = [],
    onChangeResult,
    isReview,
    listReview = [],
    onCheckReview,
    isDoing = false,
  } = props;

  const renderQuestionsWriting2 = () => {
    return category?.listQuestions?.map((question, index) => {
      let answer = props?.userAnswerText?.trim() || resultExam.filter(
        (result) => result.questionId == question.question_id
      )[0]?.answer;
      if (isReview) {
        answer = answer?.trim();
      }
      let matchOptionText = [
        question?.list_option[0].question_content?.replaceAll("/", "")?.replace(/  +/g, ' ')?.trim(),
      ];
      const isActiveReview = listReview?.find(item => item?.question_id == question?.question_id)?.checked
      return (
        <div key={type + question.question_id} id={question.question_id} style={{position: 'relative'}}>
          {isDoing && <ReviewLaterBox isActive={isActiveReview} onClick={() => onCheckReview(question?.question_id)}/>}
          <div className="item-question">
            <h3 className="label">
              {!!props?.numberReview ? props?.numberReview : (index + indexQuestion)}. {question?.question_content}
            </h3>
            {isReview ? (
              renderTextAnswerReview(answer, matchOptionText)
            ) : (
              <input
                className="width-100-percent"
                placeholder="Nhập câu trả lời..."
                value={answer}
                onChange={(e) =>
                  onChangeResult(question?.question_id, convertSingleQuote(e.target.value))
                }
              />
            )}
          </div>
          {isReview &&
            !isEmpty(question?.explain_parse?.content_question_text) && (
              <div className="text-explain-exam">
                <label>Giải thích</label>
                <div style={{ whiteSpace: "break-spaces" }}>
                  {question?.explain_parse?.content_question_text}
                </div>
              </div>
            )}
        </div>
      );
    });
  };

  const renderQuestionsWriting3 = () => {
    return category?.listQuestions?.map((question, index) => {
      let answer = props?.userAnswerText?.trim() || resultExam.filter(
        (result) => result.questionId == question.question_id
      )[0]?.answer;
      let matchOptionText = question?.list_option[0].match_option_text?.map(
        (text) => text
      );
      const isActiveReview = listReview?.find(item => item?.question_id == question?.question_id)?.checked
      return (
        <div key={type + question.question_id} id={question.question_id} style={{position: 'relative'}}>
          {isDoing && <ReviewLaterBox isActive={isActiveReview} onClick={() => onCheckReview(question?.question_id)}/>}
          <div className="item-question">
            <h3 className="label">
              {!!props?.numberReview ? props?.numberReview : (index + indexQuestion)}. {question?.question_content}
            </h3>
            {isReview ? (
              renderTextAnswerReview(answer, matchOptionText)
            ) : (
              <input
                className="width-100-percent"
                placeholder="Nhập câu trả lời..."
                value={answer}
                onChange={(e) =>
                  onChangeResult(question?.question_id, convertSingleQuote(e.target.value))
                }
              />
            )}
          </div>
          {isReview &&
            !isEmpty(question?.explain_parse?.content_question_text) && (
              <div className="text-explain-exam">
                <label>Giải thích</label>
                <div style={{ whiteSpace: "break-spaces" }}>
                  {question?.explain_parse?.content_question_text}
                </div>
              </div>
            )}
        </div>
      );
    });
  };

  const renderQuestionsWriting4 = () => {
    return category?.listQuestions?.map((question, index) => {
      let answer = props?.userAnswerText?.trim() || resultExam.filter(
        (result) => result.questionId == question.question_id
      )[0]?.answer;
      let matchOptionText = question?.list_option[0].match_option_text?.map(
        (text) => text
      );
      const isActiveReview = listReview?.find(item => item?.question_id == question?.question_id)?.checked
      return (
        <div key={type + question.question_id} id={question.question_id} style={{position: 'relative'}}>
          {isDoing && <ReviewLaterBox isActive={isActiveReview} onClick={() => onCheckReview(question?.question_id)}/>}
          <div className="item-question" style={{width: '100%'}}>
            <h3 className="label">
              {!!props?.numberReview ? props?.numberReview : (index + indexQuestion)}. {question?.question_content}
            </h3>
            <div className="d-flex" style={{width: '100%'}}>
              {!isReview && <h3 className="bold label" >{question?.hint}</h3>}
              {isReview ? (
                // <span> ________</span>
                <></>
              ) : (
                <input
                  className="flex-1"
                  style={{minWidth: '60%'}}
                  placeholder="Nhập câu trả lời..."
                  value={answer}
                  onChange={(e) =>
                    onChangeResult(question?.question_id, convertSingleQuote(e.target.value))
                  }
                />
              )}
            </div>
            {isReview && renderTextAnswerReview(answer, matchOptionText, question?.hint)}
          </div>
          {isReview &&
            !isEmpty(question?.explain_parse?.content_question_text) && (
              <div className="text-explain-exam">
                <label>Giải thích</label>
                <div style={{ whiteSpace: "break-spaces" }}>
                  {question?.explain_parse?.content_question_text}
                </div>
              </div>
            )}
        </div>
      );
    });
  };

  const renderQuestionsWriting5 = () => {
    return category?.listQuestions?.map((question, index) => {
      let answer = props?.userAnswerText?.trim() || resultExam.filter(
        (result) => result.questionId == question.question_id
      )[0]?.answer;
      let matchOptionText = question?.list_option[0].match_option_text?.map(
        (text) => text
      );
      const isActiveReview = listReview?.find(item => item?.question_id == question?.question_id)?.checked
      return (
        <div key={type + question.question_id} id={question.question_id} style={{position: 'relative'}}>
          {isDoing && <ReviewLaterBox isActive={isActiveReview} onClick={() => onCheckReview(question?.question_id)}/>}
          <div className="item-question">
            <h3 className="label">
              {!!props?.numberReview ? props?.numberReview : (index + indexQuestion)}. {question?.question_content}
            </h3>
            {isReview ? (
              renderTextAnswerReview(answer, matchOptionText)
            ) : (
              <input
                className="width-100-percent"
                placeholder="Nhập câu trả lời..."
                value={answer}
                onChange={(e) =>
                  onChangeResult(question?.question_id, convertSingleQuote(e.target.value))
                }
              />
            )}
          </div>
          {isReview &&
            !isEmpty(question?.explain_parse?.content_question_text) && (
              <div className="text-explain-exam">
                <label>Giải thích</label>
                <div style={{ whiteSpace: "break-spaces" }}>
                  {question?.explain_parse?.content_question_text}
                </div>
              </div>
            )}
        </div>
      );
    });
  };

  const renderQuestionsWriting8 = () => {
    return category?.listQuestions?.map((question, index) => {
      let answer = props?.userAnswerText?.trim() || resultExam.filter(
        (result) => result.questionId == question.question_id
      )[0]?.answer;
      const isActiveReview = listReview?.find(item => item?.question_id == question?.question_id)?.checked
      return (
        <div key={type + question.question_id} id={question.question_id} style={{position: 'relative'}}>
          {isDoing && <ReviewLaterBox isActive={isActiveReview} onClick={() => onCheckReview(question?.question_id)}/>}
          <div
            className="item-grammar9"
            style={{ flexDirection: isReview ? "column" : "row" }}
          >
            <span className="label">
              {!!props?.numberReview ? props?.numberReview : (index + indexQuestion)}. {question?.question_content}
            </span>
            <div className="row">
              {question?.list_option?.map((option) => {
                let isOptionTrue = parseInt(option?.score) !== 0;
                let isAnswerTrue = validWord(answer) == validWord(option?.match_option_text.join(""));

                return (
                  <div className="col-12 d-flex" key={option?.option_match_id}>
                    {isReview && isOptionTrue && !isAnswerTrue && (
                      <img
                        style={{ height: 24, marginLeft: -30 }}
                        src="/assets/images/ico_hover_answer.png"
                      />
                    )}
                    <CheckBoxCustom
                      status={renderStatusCheckBox(
                        isAnswerTrue,
                        isOptionTrue,
                        isReview
                      )}
                      onChange={() =>
                        onChangeResult(
                          question?.question_id,
                          option?.match_option_text.join("")
                        )
                      }
                      label={option?.match_option_text?.join("")}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          {isReview &&
            !isEmpty(question?.explain_parse?.content_question_text) && (
              <div className="text-explain-exam">
                <label>Giải thích</label>
                <div style={{ whiteSpace: "break-spaces" }}>
                  {question?.explain_parse?.content_question_text}
                </div>
              </div>
            )}
        </div>
      );
    });
  };

  const renderListQuestions = () => {
    switch (type) {
      case "writing2":
        return renderQuestionsWriting2();
      case "writing3":
        return renderQuestionsWriting3();
      case "writing4":
        return renderQuestionsWriting4();
      case "writing5":
        return renderQuestionsWriting5();
      case "writing8":
        return renderQuestionsWriting8();
    }
  };

  return (
    <div className="item-writing" style={{ marginTop: 16 }}>
      <label className="title-question title_question_width">
        {category?.group_content}
      </label>
      <div className={`content-question ${isReview ? 'content-question-review' : ''}`}>{renderListQuestions()}</div>
    </div>
  );
};
