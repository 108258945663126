import { isEmpty } from "lodash";
import React, { useEffect } from "react";
import { renderFormAnswer, renderTextAnswerReview } from ".";
import { configConstants } from "../../_constants";
import { AudioCustom, AudioV2 } from "../Audio";
import { CheckBoxCustom, renderStatusCheckBox } from "./CheckBoxCustom";
import "./styles.scss";
import ReviewLaterBox from "./ReviewLaterBox";
import { convertSingleQuote, validWord } from "../../_helpers/utils";

export const ExamListening = (props) => {
  const {
    type,
    category,
    indexQuestion,
    resultExam = [],
    onChangeResult,
    isReview,
    listReview = [],
    onCheckReview,
    isDoing = false
  } = props;

  const renderQuestionsListening1 = () => {
    return category?.listQuestions?.map((question, index) => {
      let answer =  props?.userAnswerText?.trim() || resultExam.filter(
        (result) => result.questionId == question.question_id
      )[0]?.answer;
      let matchOptionText = question?.list_option[0].match_option_text?.map(
        (text) => text
      );
      const isActiveReview = listReview?.find(item => item?.question_id == question?.question_id)?.checked
      return (
        <div key={type + question.question_id} id={question.question_id} style={{position: 'relative'}}>
          {isDoing && <ReviewLaterBox isActive={isActiveReview} onClick={() => onCheckReview(question?.question_id)}/>}
          <div className="item-question">
            <h3 className="">
              {!!props?.numberReview ? props?.numberReview : (index + indexQuestion)}. {question.question_content}
            </h3>
            {isReview ? (
              renderTextAnswerReview(answer, matchOptionText)
            ) : (
              <input
                className="width-100-percent"
                placeholder="Nhập câu trả lời..."
                value={answer}
                onChange={(e) =>
                  onChangeResult(question?.question_id, convertSingleQuote(e.target.value))
                }
              />
            )}
          </div>
          {isReview &&
            !isEmpty(question?.explain_parse?.content_question_text) && (
              <div className="text-explain-exam">
                <label>Giải thích</label>
                <div style={{ whiteSpace: "break-spaces" }}>
                  {question?.explain_parse?.content_question_text}
                </div>
              </div>
            )}
        </div>
      );
    });
  };

  const renderQuestionsListening3 = () => {
    return category?.listQuestions?.map((question, index) => {
      let answer = props?.userAnswerText?.trim() || resultExam.filter(
        (result) => result.questionId == question.question_id
      )[0]?.answer;
      const isActiveReview = listReview?.find(item => item?.question_id == question?.question_id)?.checked
      return (
        <div key={type + question.question_id} id={question.question_id} style={{position: 'relative'}}>
          {isDoing && <ReviewLaterBox isActive={isActiveReview} onClick={() => onCheckReview(question?.question_id)}/>}
          <div
            className="item-grammar9"
            style={{ flexDirection: isReview ? "column" : "row" }}
          >
            <span className="label">
              {!!props?.numberReview ? props?.numberReview : (index + indexQuestion)}. {question.question_content}.
            </span>
            <div className="row">
              {question?.list_option?.map((option) => {
                let isOptionTrue = parseInt(option?.score) !== 0;
                let isAnswerTrue =
                  answer == option?.match_option_text.join("");
                return (
                  <div className="col-12 d-flex" key={option?.option_match_id}>
                    {isReview && isOptionTrue && !isAnswerTrue && (
                      <img
                        style={{ height: 24, marginLeft: -30 }}
                        src="/assets/images/ico_hover_answer.png"
                      />
                    )}
                    <CheckBoxCustom
                      status={renderStatusCheckBox(
                        isAnswerTrue,
                        isOptionTrue,
                        isReview
                      )}
                      onChange={() =>
                        onChangeResult(
                          question.question_id,
                          option?.match_option_text.join("")
                        )
                      }
                      label={option?.match_option_text?.join("")}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          {isReview &&
            !isEmpty(question?.explain_parse?.content_question_text) && (
              <div className="text-explain-exam">
                <label>Giải thích</label>
                <div style={{ whiteSpace: "break-spaces" }}>
                  {question?.explain_parse?.content_question_text}
                </div>
              </div>
            )}
        </div>
      );
    });
  };

  const renderQuestionsListening5 = () => {
    const newResultExam = resultExam?.map(item => {
      let answer = item?.answer
      if (item?.answer === true || validWord(item?.answer) === 'true') {
        answer = 'T'
      }
      if (item?.answer === false || validWord(item?.answer) === 'false') {
        answer = 'F'
      }
      return {
        ...item,
        answer
      }
    })
    return category?.listQuestions?.map((question, index) => {
      let answer = props?.userAnswerText?.trim() || newResultExam.filter(
        (result) => result.questionId == question.question_id
      )[0]?.answer;
      let optionTextTrue = question?.list_option[0]?.match_option_text.join("");
      const isActiveReview = listReview?.find(item => item?.question_id == question?.question_id)?.checked
      return (
        <div key={type + question.question_id} id={question.question_id} style={{position: 'relative'}}>
          {isDoing && <ReviewLaterBox isActive={isActiveReview} onClick={() => onCheckReview(question?.question_id)}/>}
          <div
            className="item-grammar9"
            style={{ flexDirection: isReview ? "column" : "row" }}
          >
            <span className="label">
              {!!props?.numberReview ? props?.numberReview : (index + indexQuestion)}. {question.question_content}
            </span>
            <div className="row">
              <div className="col-6 d-flex">
                {isReview && optionTextTrue == "T" && answer !== "T" && (
                  <img
                    style={{ height: 24, marginLeft: -30 }}
                    src="/assets/images/ico_hover_answer.png"
                  />
                )}
                <CheckBoxCustom
                  status={renderStatusCheckBox(
                    answer == "T",
                    optionTextTrue == "T",
                    isReview
                  )}
                  onChange={() => onChangeResult(question.question_id, "T")}
                  label="True"
                />
              </div>
              <div className="col-6 d-flex">
                {isReview && optionTextTrue == "F" && answer !== "F" && (
                  <img
                    style={{ height: 24, marginLeft: -30 }}
                    src="/assets/images/ico_hover_answer.png"
                  />
                )}
                <CheckBoxCustom
                  status={renderStatusCheckBox(
                    answer == "F",
                    optionTextTrue == "F",
                    isReview
                  )}
                  onChange={() => onChangeResult(question.question_id, "F")}
                  label="False"
                />
              </div>
              {/* {question?.list_option?.map(option => (
                                <div className="col-12" key={option?.option_match_id}>
                                    <CheckBoxCustom 
                                        status={answer == option?.match_option_text.join('') && "checked"} 
                                        onChange={() => onChangeResult(question.question_id, option?.match_option_text.join(''))} 
                                        label={option?.match_option_text?.join('')}/>
                                </div>
                            ))} */}
            </div>
          </div>
          {isReview &&
            !isEmpty(question?.explain_parse?.content_question_text) && (
              <div className="text-explain-exam">
                <label>Giải thích</label>
                <div style={{ whiteSpace: "break-spaces" }}>
                  {question?.explain_parse?.content_question_text}
                </div>
              </div>
            )}
        </div>
      );
    });
  };

  const renderQuestionsListening9 = () => {
    return category?.listQuestions?.map((question, index) => {
      let answer = resultExam.filter(
        (result) => result.questionId == question.question_id
      )[0]?.answer;
      let textAnswer = props?.userAnswerText?.trim() || (!!answer?.length && typeof answer === 'object'
        ? answer.find((item) => item.type == "input")?.value?.trim()
        : (typeof answer === 'string' ? answer : ''));
      let matchOptionText = question?.list_option[0].match_option_text?.map(
        (text) => text
      );
      const isActiveReview = listReview?.find(item => item?.question_id == question?.question_id)?.checked
      return (
        <div key={type + question.question_id} id={question.question_id} style={{position: 'relative'}}>
          {isDoing && <ReviewLaterBox isActive={isActiveReview} onClick={() => onCheckReview(question?.question_id)}/>}
          <div className="item-grammar1">
            <label className="order">{!!props?.numberReview ? props?.numberReview : (index + indexQuestion)}.</label>
            {renderFormAnswer(
              question?.list_option,
              answer,
              !isReview,
              (value) => onChangeResult(question.question_id, value)
            )}
          </div>
          {isReview && renderTextAnswerReview(textAnswer, matchOptionText)}
          {isReview && !isEmpty(question?.option_explain) && (
            <div className="text-explain-exam">
              <label>Giải thích</label>
              <div style={{ whiteSpace: "break-spaces" }}>
                {question?.option_explain}
              </div>
            </div>
          )}
        </div>
      );
    });
  };

  const renderListQuestions = () => {
    switch (type) {
      case "listening1":
        return renderQuestionsListening1();
      case "listening2":
        return renderQuestionsListening1();
      case "listening3":
        return renderQuestionsListening3();
      case "listening5":
        return renderQuestionsListening5();
      case "listening9":
        return renderQuestionsListening9();
    }
  };

  return (
    <div className="exam-listening">
      <div className={`content-question ${isReview ? 'content-question-review' : ''}`}>
        <h3 className="title-question title_question_width">{category?.group_content}</h3>
        <div className="content-audio">
          <AudioV2 link={configConstants.BASE_URL + category?.group_file} />
        </div>
        {renderListQuestions()}
      </div>
    </div>
  );
};
