import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Alert } from "../../../_components/Alert";
import { teacherActions, userActions } from "../../../_actions";
import { userConstants } from "./../../../_constants";
import { SelectDate } from "../../../_components/Calendar";
import { Header } from "./../../../_components/Admin/Header";
import $ from "jquery";
import { SelectAsDiv } from "./../../../_components/Select";
import styled from "styled-components";
import moment from "moment";
import { isEmpty } from "lodash";
import InputDate from "../../../_components/Auth/InputDate";
import dayjs from "dayjs";
import InputSelectSearch from "../../../_components/Auth/InputSelectSearch";
import { apiCaller } from "../../../_helpers";

const ProfileImage = styled.img`
  object-fit: cover;
`;

const CustomSelect = styled(SelectAsDiv)`
  background-image: unset;
  .select-styled {
    height: 44px;
    line-height: 36px;
    font-size: 14px;
    padding-left: 20px;
    border-radius: 18px;
    outline: none;
    border: 1px solid #fff;
    box-shadow: 0 1px 8px 0 rgb(21 27 38 / 15%);
    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
      top: 7px;
    }
  }
  img {
    display: none;
  }
  ul li {
    height: 40px;
  }
`;

const GRADE_OPTIONS = [
  // { id: "1", name: "1" },
  // { id: "2", name: "2" },
  // { id: "3", name: "3" },
  // { id: "4", name: "4" },
  // { id: "5", name: "5" },
  { id: "Khối 6", name: "Khối 6" },
  { id: "Khối 7", name: "Khối 7" },
  { id: "Khối 8", name: "Khối 8" },
  { id: "Khối 9", name: "Khối 9" },
  // { id: "10", name: "10" },
  // { id: "11", name: "11" },
  // { id: "12", name: "12" },
];

function EditProfile() {
  const dispatch = useDispatch();
  const authentication = useSelector((state) => state.authentication);
  const dataProfile = useSelector((state) => state.profile.user_info.data);
  const alert = useSelector((state) => state.alert);

  // console.log(dataProfile);

  const [inputs, setInputs] = useState({
    fullname: dataProfile.fullname,
    gender: dataProfile.gender,
    birthday:
      dataProfile?.birthday && moment(dataProfile.birthday).isValid()
        ? new Date(dataProfile.birthday.replaceAll("-", "/"))
        : new Date(),
    email: dataProfile.email,
    phone: dataProfile.phone,
    school: dataProfile.school,
  });

  const history = useHistory();
  const baseUrl = useSelector((state) => state.profile.base_url);

  const [submitted, setSubmitted] = useState(false);
  const [changeAvatar, setChangeAvatar] = useState(true);
  const [availableEdit, setAilableEdit] = useState(false);
  const [feildInputInValid, setFeildInputInvalid] = useState("");
  const [provinceList, setProvinceList] = useState([]);
  const [province, setProvince] = useState({
    title: "",
    value: "",
  });
  const [districtList, setDistrictList] = useState([]);
  const [district, setDistrict] = useState({
    title: "",
    value: "",
  });
  const [schoolList, setSchoolList] = useState([]);
  const [school, setSchool] = useState({
    title: dataProfile?.school,
    value: dataProfile?.school,
    id: dataProfile?.school,
  });
  const [errProvince, setErrProvince] = useState("");
  const [errDistrict, setErrDistrict] = useState("");

  const { fullname, gender, birthday, email, phone } = inputs;
  
  useEffect(() => {
    setInputs({
      fullname: dataProfile.fullname,
      gender: dataProfile.gender,
      birthday:
        dataProfile?.birthday && moment(dataProfile.birthday).isValid()
          ? new Date(dataProfile.birthday.replaceAll("-", "/"))
          : null,
      email: dataProfile.email,
      phone: dataProfile.phone,
      school: dataProfile.school,
    });
  }, [dataProfile]);

  useEffect(() => {
    if (!!province.value) {
      getDistrictList(province.value)
    }
  }, [province.value])

  useEffect(() => {
    if (!!district.value) {
      getSchoolList(district.value)
    }
  }, [district.value])

  useEffect(() => {
    dispatch(teacherActions.getProfileV2(authentication.id));
    getProvinceList();
    $(document).ready(function ($) {
      $("#avatar").change(function () {
        readURL(this, "#preview-avatar");
      });

      function readURL(input, element) {
        if (input.files && input.files[0]) {
          var reader = new FileReader();
          reader.onload = function (e) {
            $(element).attr("src", e.target.result);
          };
          reader.readAsDataURL(input.files[0]);
        }
      }
    });
  }, []);
  
  const getProvinceList = () => {
    apiCaller("/api_category/provinces?country_code=vn").then((res) => {
      if (res.status) {
        if(dataProfile?.province_alias) {
          const province = res?.data?.find(item => item?.province_alias === dataProfile?.province_alias)
          if(province) {
            setProvince({
              value: province?.province_alias,
              title: province?.province
            })
          }
        }
        setProvinceList(res.data);
      }
    });
  };

  const getDistrictList = (provinceAlias) => {
    apiCaller(`/api_category/districts?province_alias=${provinceAlias}`).then(
      (res) => {
        if (res.status) {
          if(dataProfile?.district_alias) {
            const district = res?.data?.find(item => item?.district_alias === dataProfile?.district_alias)
            if(district) {
              setDistrict({
                value: district?.district_alias,
                title: district?.district
              })
            }
          }
          setDistrictList(res.data);
        }
      }
    );
  };

  const getSchoolList = (districtAlias) => {
    apiCaller(`/api_category/schools?district_alias=${districtAlias}`).then(
      (res) => {
        if (res.status) {
          setSchoolList(res.data);
        }
      }
    );
  };

  const changeProvice = (newProvince) => {
    if (newProvince.value !== province.value) {
      setAilableEdit(true);
      setDistrict({
        title: "",
        value: "",
      });
      setSchool({
        title: "",
        value: "",
      });
      setProvince(newProvince);
      getDistrictList(newProvince.value);
    }
  };

  const changeDistrict = (newDistrict) => {
    if (newDistrict.value !== district.value) {
      setAilableEdit(true);
      setSchool({
        title: "",
        value: "",
      });
      setDistrict(newDistrict);
      getSchoolList(newDistrict.value);
    }
  };

  const changeSchool = (school) => {
    setAilableEdit(true);
    setSchool(school);
  };

  function handleChange(e) {
    setAilableEdit(true);
    const { name, value } = e.target;
    if (name === "phone") {
      let numberPhone = e.target.value.replace(/\D/, "");
      setInputs((inputs) => ({ ...inputs, [name]: numberPhone }));
      setFeildInputInvalid("");
    } else {
      setInputs((inputs) => ({ ...inputs, [name]: value }));
    }
  }

  const changeBirthday = (birthday) => {
    const today = new Date();
    setAilableEdit(true);
    if (dayjs(birthday).isBefore(dayjs(today))) {
      setInputs((inputs) => ({ ...inputs, ["birthday"]: birthday }));
    } else {
      setInputs((inputs) => ({ ...inputs, ["birthday"]: today }));
    }
  };

  function handleOnBlur(e) {
    const { name } = e.target;
    let value = e.target.value.trim();
    if (name === "phone") {
      let numberPhone = e.target.value.replace(/\D/, "").trim();
      if(!!numberPhone) {
        if ([10, 11].includes(numberPhone.length)) {
          setInputs((inputs) => ({ ...inputs, [name]: numberPhone }));
          setFeildInputInvalid("");
        } else {
          setFeildInputInvalid(name);
        }
      }
    } else {
      setInputs((inputs) => ({ ...inputs, [name]: value }));
    }
  }

  function handleChangeFile(event) {
    setInputs((inputs) => ({
      ...inputs,
      avatar: event.target.files[0],
    }));
    setChangeAvatar(true);
    setAilableEdit(true);
  }

  function openSelectFile(e) {
    setChangeAvatar(false);
    e.preventDefault();
    $("#avatar").trigger("click");
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setSubmitted(true);
    if (changeAvatar && inputs["avatar"]) {
      var uploadAvatar = new FormData();
      uploadAvatar.append("file", inputs["avatar"]);
      // dispatch(userActions.uploadAvatar(uploadAvatar));
      await apiCaller("/api_user/update_avatar", "POST", uploadAvatar, null, false)
    }
    if (validateParam()) {
      const params = {
        ...inputs,
        class: inputs.grade_name,
        grade_id:
          inputs.grade_name == "Khối 6"
            ? 2
            : inputs.grade_name == "Khối 7"
            ? 4
            : inputs.grade_name == "Khối 8"
            ? 3
            : inputs.grade_name == "Khối 9"
            ? 6
            : "",
        birthday: !!inputs.birthday ? 
          (inputs.birthday.getFullYear() +
          "-" +
          (inputs.birthday.getMonth() + 1) +
          "-" +
          inputs.birthday.getDate()) : undefined,
        province_alias: province.value,
        district_alias: district.value,
        school: school.value,
      };
      if (dataProfile?.change_avatar) {
        params.avatar = getSrcAvatar(true)
      }
      // delete params.grade_name;

      dispatch(teacherActions.updateProfile(params));
    }
  }

  function changeGender(gender) {
    setAilableEdit(true);
    setInputs({
      ...inputs,
      gender: gender,
    });
  }
  
  function validateParam() {
    if (
      fullname?.trim()
      // gender &&
      // birthday
      // email &&
      // phone
    ) {
      if (changeAvatar && availableEdit) {
        return true;
      } else if (availableEdit) {
        return true;
      } else {
        return false;
      }
    }
    // return (changeAvatar && availableEdit && fullname && gender && birthday && email && phone && school) ? true : false;
  }
  
  const renderLocationIcon = () => {
    return (
        <svg width="20" height="22" viewBox="0 0 20 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.66899 0.148004C11.9476 0.148298 14.1514 0.960881 15.8848 2.43975C17.6183 3.91862 18.7678 5.96689 19.127 8.21697C19.3404 9.86835 19.076 11.5464 18.365 13.0521C17.3663 15.3307 16.1212 17.4933 14.652 19.501C13.237 21.474 11.735 23.386 10.261 25.318C10.2033 25.4308 10.1158 25.5255 10.008 25.592C9.90014 25.6584 9.77612 25.6941 9.64946 25.6949C9.52279 25.6957 9.39829 25.6618 9.28959 25.5967C9.1809 25.5317 9.09215 25.438 9.033 25.326C6.43024 22.2683 4.12009 18.9733 2.13298 15.4841C1.53469 14.3808 1.00894 13.2397 0.559001 12.068C0.159563 11.0171 -0.0083814 9.89235 0.0666918 8.77056C0.141765 7.64878 0.458024 6.5564 0.993938 5.56805C1.67343 4.11537 2.71252 2.86017 4.01274 1.92132C5.31295 0.982471 6.83131 0.391058 8.42399 0.203058C8.63983 0.172163 8.85712 0.152388 9.07499 0.143976C9.27199 0.142976 9.47099 0.148004 9.66899 0.148004ZM9.63395 23.957C9.71895 23.857 9.76898 23.813 9.80998 23.757C11.02 22.144 12.2599 20.5509 13.4309 18.9109C14.8084 17.0324 15.9975 15.0227 16.981 12.9109C17.6695 11.5695 17.9586 10.0589 17.814 8.55804C17.5669 6.82634 16.7758 5.21798 15.5552 3.96502C14.3346 2.71206 12.7475 1.87927 11.0229 1.58697C9.29822 1.29467 7.52532 1.55789 5.96 2.33868C4.39469 3.11946 3.11793 4.3774 2.31401 5.93096C1.83282 6.76225 1.53499 7.68691 1.44047 8.64276C1.34596 9.5986 1.45697 10.5635 1.76603 11.473C2.18112 12.6048 2.67772 13.705 3.25199 14.7649C4.7301 17.3876 6.41406 19.8889 8.288 22.2451C8.721 22.8141 9.17103 23.371 9.63603 23.96L9.63395 23.957Z"
                fill="#00B9B7"
            />
            <path
                d="M9.969 29.575C7.58968 29.6284 5.21823 29.2828 2.95301 28.5529C2.22831 28.3141 1.55332 27.945 0.961069 27.4639C0.685165 27.277 0.458836 27.0258 0.301767 26.7319C0.144697 26.4379 0.0615246 26.1103 0.0594572 25.777C0.0573898 25.4438 0.136515 25.115 0.289926 24.8191C0.443337 24.5233 0.666509 24.2692 0.940072 24.0789C1.83837 23.3758 2.88136 22.8806 3.99403 22.629C4.36403 22.524 4.74508 22.4509 5.12208 22.3699C5.21154 22.3409 5.30599 22.3308 5.39955 22.3404C5.4931 22.3499 5.58366 22.3789 5.66541 22.4253C5.74717 22.4718 5.81834 22.5349 5.8744 22.6104C5.93046 22.6859 5.97014 22.7722 5.99098 22.8639C6.00691 22.9561 6.00431 23.0504 5.98316 23.1415C5.96202 23.2326 5.92283 23.3185 5.86793 23.3942C5.81303 23.4699 5.74347 23.5338 5.66346 23.5822C5.58345 23.6306 5.49452 23.6625 5.40199 23.6759C4.63999 23.8829 3.86699 24.069 3.13099 24.347C2.64519 24.5395 2.19142 24.8045 1.78504 25.133C1.26304 25.544 1.28508 25.9779 1.79908 26.4119C2.52293 26.9682 3.35928 27.36 4.25001 27.56C6.25505 28.092 8.32984 28.3133 10.402 28.216C12.2972 28.2101 14.1777 27.882 15.963 27.2459C16.4782 27.0466 16.9662 26.7829 17.415 26.461C18.042 26.01 18.025 25.5319 17.415 25.0469C16.6085 24.4697 15.6907 24.0666 14.72 23.863C14.453 23.791 14.179 23.7439 13.91 23.6769C13.8223 23.6617 13.7385 23.629 13.6638 23.5805C13.5891 23.532 13.5251 23.4689 13.4755 23.3949C13.4258 23.321 13.3917 23.2378 13.3751 23.1503C13.3586 23.0628 13.3599 22.9729 13.379 22.8859C13.3934 22.8002 13.4254 22.7183 13.4729 22.6456C13.5204 22.5728 13.5824 22.5106 13.655 22.4629C13.7277 22.4152 13.8095 22.3831 13.8952 22.3685C13.9808 22.3538 14.0687 22.3571 14.1531 22.378C15.487 22.5791 16.7667 23.0467 17.916 23.753C18.4972 24.0842 18.9355 24.6184 19.147 25.253C19.2423 25.5912 19.2439 25.9489 19.1516 26.288C19.0593 26.6271 18.8766 26.9346 18.6231 27.1779C18.0952 27.7153 17.4553 28.1298 16.749 28.3919C15.2727 28.9722 13.7171 29.3262 12.135 29.4419C11.312 29.5159 10.479 29.543 9.969 29.575Z"
                fill="#00B9B7"
            />
            <path
                d="M9.74695 12.8121C8.96881 12.7982 8.21228 12.5536 7.57324 12.1094C6.9342 11.6652 6.44148 11.0414 6.15735 10.3168C5.87322 9.59228 5.81056 8.79967 5.97729 8.03948C6.14403 7.27929 6.53258 6.58568 7.09387 6.04656C7.65516 5.50744 8.36383 5.14714 9.13013 5.01116C9.89642 4.87518 10.6858 4.96968 11.3983 5.28277C12.1108 5.59585 12.7145 6.11341 13.1326 6.76983C13.5507 7.42624 13.7644 8.19196 13.7469 8.97002C13.7166 10.0068 13.2805 10.9903 12.5325 11.7088C11.7844 12.4273 10.7841 12.8235 9.74695 12.8121ZM12.395 8.88506C12.4002 8.3647 12.2505 7.85457 11.9652 7.41936C11.6799 6.98416 11.2717 6.64353 10.7925 6.44073C10.3132 6.23792 9.7846 6.182 9.27356 6.2802C8.76252 6.37841 8.29218 6.62624 7.92224 6.99224C7.5523 7.35823 7.29947 7.82591 7.1958 8.33587C7.09212 8.84583 7.1423 9.37511 7.33996 9.8565C7.53763 10.3379 7.87378 10.7496 8.30591 11.0396C8.73803 11.3296 9.24658 11.4846 9.76697 11.485C10.4601 11.4882 11.1262 11.2163 11.6189 10.7288C12.1116 10.2413 12.3908 9.57818 12.395 8.88506Z"
                fill="#00B9B7"
            />
        </svg>
    );
  };

 const renderSchoolIcon = () => {
  return (
      <svg width={28} height={30} viewBox="0 0 28 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M13.5431 23.29C14.767 23.29 15.7591 22.2978 15.7591 21.074C15.7591 19.8501 14.767 18.858 13.5431 18.858C12.3193 18.858 11.3271 19.8501 11.3271 21.074C11.3271 22.2978 12.3193 23.29 13.5431 23.29Z"
              stroke="#00B9B7"
              strokeWidth="1.5"
              strokeMiterlimit={10}
          />
          <path
              d="M11.7542 34.648V29.279C11.7542 29.1864 11.7909 29.0977 11.8563 29.0323C11.9218 28.9668 12.0106 28.93 12.1031 28.93H15.3102C15.4027 28.93 15.4914 28.9668 15.5569 29.0323C15.6223 29.0977 15.6592 29.1864 15.6592 29.279V34.6711"
              stroke="#00B9B7"
              strokeWidth="1.5"
              strokeMiterlimit={10}
          />
          <path
              d="M3.00886 34.672H24.4438C24.5248 34.672 24.5908 34.583 24.5908 34.472V27.014C24.5908 26.905 24.6569 26.8141 24.7379 26.8141H26.3078C26.4308 26.8141 26.4989 26.625 26.4249 26.494L24.4108 22.963C24.4001 22.941 24.3836 22.9223 24.3632 22.9088C24.3428 22.8953 24.3193 22.8874 24.2949 22.886H18.6898C18.6088 22.886 18.5428 22.797 18.5428 22.686V18.551C18.5428 18.442 18.6088 18.351 18.6898 18.351H20.8748C21.0058 18.351 21.0748 18.1371 20.9748 18.0121L13.9359 8.51804C13.9247 8.5007 13.9094 8.48641 13.8913 8.47653C13.8732 8.46665 13.853 8.46152 13.8323 8.46152C13.8117 8.46152 13.7915 8.46665 13.7734 8.47653C13.7553 8.48641 13.74 8.5007 13.7288 8.51804L6.70088 17.709C6.60088 17.834 6.67085 18.051 6.80085 18.051H8.75386C8.83486 18.051 8.90083 18.1401 8.90083 18.2511V22.688C8.90083 22.798 8.83486 22.888 8.75386 22.888H2.9438C2.91598 22.8899 2.88936 22.8998 2.86702 22.9165C2.84467 22.9331 2.82756 22.9559 2.81782 22.982L1.20686 26.513C1.14686 26.645 1.21784 26.813 1.33284 26.813H2.71382C2.79582 26.813 2.86177 26.902 2.86177 27.013V34.471C2.86177 34.583 2.92786 34.672 3.00886 34.672Z"
              stroke="#00B9B7"
              strokeWidth="1.5"
              strokeMiterlimit={10}
          />
          <path
              d="M13.8459 8.38397V1.737H19.0869C19.2195 1.737 19.3468 1.78972 19.4406 1.88348C19.5343 1.97725 19.5869 2.10439 19.5869 2.237V6.07196C19.5869 6.20457 19.5343 6.33183 19.4406 6.4256C19.3468 6.51937 19.2195 6.57196 19.0869 6.57196H13.8459"
              stroke="#00B9B7"
              strokeWidth="1.5"
              strokeMiterlimit={10}
          />
      </svg>
  );
  };

  const getSrcAvatar = (notBaseUrl = false) => {
    let src = dataProfile.avatar;
    if (dataProfile?.change_avatar) {
      if (authentication.role === userConstants.ROLE_STUDENT) {
        if (inputs.gender === 'female') {
          src = userConstants.AVATAR_STUDENT_FEMALE;
        } else {
          src = userConstants.AVATAR_STUDENT_MALE;
        }
      } else {
        if (inputs.gender === 'female') {
          src = userConstants.AVATAR_TEACHER_FEMALE;
        } else {
          src = userConstants.AVATAR_TEACHER_MALE;
        }
      }
    }
    return (notBaseUrl ? '' : baseUrl) + src
  }
  
  return (
    <div className="sunE-container flex bg-main-color">
      <div className="sunE-right-container flex-column">
        <Header
          title={
            authentication.role === userConstants.ROLE_TEACHER
              ? "Chỉnh sửa thông tin"
              : "Hồ sơ"
          }
          isBack
        />
        <form
          className="sunE-container-box hoso flex-1"
          onSubmit={handleSubmit}
          noValidate={true}
        >
          <div
            className={
              "avatar-edit rel" +
              (authentication.role === userConstants.ROLE_STUDENT
                ? " student"
                : "")
            }
          >
            {authentication.role === userConstants.ROLE_STUDENT && (
              <img
                style={{ width: "100%" }}
                src="/assets/images/student/bg_edit_student_profile.png"
                alt="bg_edit_student_profile"
                className="bg_edit_student_profile"
              />
            )}
            <div
              className={
                "avatar-edit-box box-shadow rel" +
                (authentication.role === userConstants.ROLE_STUDENT
                  ? " student"
                  : "")
              }
            >
              <ProfileImage
                id="preview-avatar"
                src={getSrcAvatar()}
                alt="avatar"
              />
              <input
                type="file"
                name="avatar"
                accept="image/*"
                className="hide"
                onChange={handleChangeFile}
                id="avatar"
              />
              <div className="img-edit-avt" onClick={openSelectFile}>
                <img
                  src="/assets/images/hoso/ico_edit_avt.png"
                  alt="ico_edit_avt"
                />
              </div>
            </div>
            <div className="flex-m">
              <div className="flex-1 mr-20">
                <div className="sunE-input-gr">
                  <label>Họ và tên</label>
                  <input
                    className="capitalize"
                    type="text"
                    name="fullname"
                    value={inputs.fullname}
                    onChange={handleChange}
                    placeholder="Họ và tên"
                    onBlur={handleOnBlur}
                  />
                </div>
                <div className="sunE-input-gr select-date-profile">
                  <label>Ngày sinh</label>
                  {/* <SelectDate
                    dateFormat="dd/MM/yyyy"
                    name="birthday"
                    selected={birthday}
                    disableMouseFocus={true}
                    onChange={(birthday) =>
                      console.log(birthday) +
                      setInputs({ ...inputs, birthday }) + setAilableEdit(true)
                    }
                  /> */}
                  <InputDate
                    className="field_birthday"
                    value={birthday}
                    setValue={changeBirthday}
                    name="birthday"
                    // renderLabelIcon={renderBirthdayIcon}
                    errorText={""}
                    // setErrorText={setBirthdayWarning}
                    errorAbsolute={true}
                  ></InputDate>
                </div>
                <div className="sunE-input-gr">
                  <label>Email</label>
                  <input
                    type="email"
                    name="email"
                    defaultValue={dataProfile.email}
                    onChange={handleChange}
                    readOnly
                  />
                </div>
                {/* {authentication.role === userConstants.ROLE_STUDENT && (
                  <div className="sunE-input-gr">
                    <label>Trường</label>
                    <input
                      type="text"
                      name="school"
                      defaultValue={dataProfile.school}
                      onChange={handleChange}
                      placeholder="Trường"
                    />
                  </div>
                )} */}
              </div>
              <div className="flex-1 ml-20">
                <div className="sunE-input-gr">
                  <label>Giới tính</label>
                  <div
                    className={
                      "flex-in" +
                      (submitted && inputs.gender === "" ? " err" : "")
                    }
                  >
                    <div className="male-box">
                      <div
                        className={
                          "sunE-male-female flex-m male" +
                          (inputs.gender === "male" ? " active" : "")
                        }
                        onClick={() => changeGender("male")}
                      >
                        <img
                          src="/assets/images/icon/ico_male.png"
                          alt={"ico_male"}
                          className="ico_male_active"
                        />
                        <img
                          src="/assets/images/icon/ico_male_2.png"
                          alt={"ico_male_2"}
                          className="ico_male"
                        />
                        <span>Nam</span>
                      </div>
                    </div>
                    <div className="female-box">
                      <div
                        className={
                          "sunE-male-female flex-m female" +
                          (inputs.gender === "female" ? " active" : "")
                        }
                        onClick={() => changeGender("female")}
                      >
                        <img
                          src="/assets/images/icon/ico_female.png"
                          alt={"ico_female"}
                          className="ico_female"
                        />
                        <img
                          src="/assets/images/icon/ico_female_2.png"
                          alt={"ico_female_2"}
                          className="ico_female_active"
                        />
                        <span>Nữ</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sunE-input-gr">
                  <label>
                    Số điện thoại{" "}
                    {feildInputInValid === "phone" ? (
                      <span className="text-error-input">
                        (Số điện thoại hợp lệ gồm 10-11 số!)
                      </span>
                    ) : null}
                  </label>
                  <input
                    className={
                      feildInputInValid === "phone" ? "input-error" : ""
                    }
                    type="text"
                    name="phone"
                    defaultValue={dataProfile.phone}
                    value={inputs.phone}
                    onChange={handleChange}
                    placeholder="Số điện thoại"
                    onBlur={handleOnBlur}
                  />
                </div>
                {/* {authentication.role === userConstants.ROLE_TEACHER && (
                  <div className="sunE-input-gr">
                    <label>Nơi công tác</label>
                    <input
                      type="text"
                      name="school"
                      value={inputs.school}
                      onChange={handleChange}
                      placeholder="Nơi công tác"
                      onBlur={handleOnBlur}
                    />
                  </div>
                )} */}
                {authentication.role === userConstants.ROLE_STUDENT && (
                  <div className="sunE-input-gr" style={{marginTop: 22}}>
                    <label>Khối</label>
                    {/* <input
                      type="text"
                      name="classname"
                      defaultValue={dataProfile.class}
                      onChange={handleChange}
                      placeholder="Lớp"
                    /> */}
                    <CustomSelect
                      keySelected={inputs.grade_name}
                      textDefault={dataProfile.grade_name}
                      data={GRADE_OPTIONS}
                      onChangeSelect={(grade_name) => {
                        setAilableEdit(true);
                        setInputs({ ...inputs, grade_name });
                      }}
                      className="edit-profile-grade-input"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="mt-20">
              <div className="sunE-input-gr" style={{margin: 0}}>
                <label>
                  {authentication.role !== userConstants.ROLE_TEACHER
                    ? `Nơi học tập`
                    : `Nơi công tác`}
                </label>
              </div>
              <div className="flex-m">
                <InputSelectSearch
                  // className="field_item field_province"
                  value={province}
                  setValue={changeProvice}
                  options={provinceList.map((item) => {
                    return {
                      value: item.province_alias,
                      title: item.province,
                    };
                  })}
                  placeholder="Tỉnh/Thành phố"
                  titleHeader="Chọn Tỉnh/Thành phố"
                  renderLabelIcon={renderLocationIcon}
                  bottomErr={true}
                  errorText={errProvince}
                  setErrorText={setErrProvince}
                  styleInputSelectWrapper={{
                    height: '44px',
                    marginBottom: 0,
                    backgroundColor: '#FFFFFF',
                    padding: '0 16px'
                  }}
                  className='profile-input-select'
                />
                <InputSelectSearch
                  // className="field_item field_district"
                  value={district}
                  setValue={changeDistrict}
                  options={districtList.map((item) => {
                    return {
                      value: item.district_alias,
                      title: item.district,
                    };
                  })}
                  placeholder="Quận/Huyện"
                  titleHeader="Chọn Quận/Huyện"
                  renderLabelIcon={renderLocationIcon}
                  disabledClick={!province.value}
                  onClickDisable={() => {
                    setErrProvince("Vui lòng chọn tỉnh / thành phố");
                  }}
                  errorText={errDistrict}
                  setErrorText={setErrDistrict}
                  bottomErr={true}
                  styleInputSelectWrapper={{
                    height: '44px',
                    marginBottom: 0,
                    backgroundColor: '#FFFFFF',
                    padding: '0 16px'
                  }}
                  className={"profile-input-select ml-20"}
                />
                <InputSelectSearch
                  // className="field_item field_school"
                  value={school}
                  setValue={changeSchool}
                  options={schoolList.map((item) => {
                    return {
                      value: item.school_name,
                      title: item.school_name,
                      id: item?.id,
                    };
                  })}
                  placeholder="Trường"
                  titleHeader="Chọn Trường"
                  renderLabelIcon={renderSchoolIcon}
                  disabledClick={!district.value}
                  onClickDisable={() => {
                    setErrDistrict("Vui lòng chọn quận / huyện");
                    if (!province.value) {
                      setErrProvince("Vui lòng chọn tỉnh / thành phố");
                    }
                  }}
                  bottomErr={true}
                  styleInputSelectWrapper={{
                    height: '44px',
                    marginBottom: 0,
                    backgroundColor: '#FFFFFF',
                    padding: '0 16px'
                  }}
                  className={"profile-input-select ml-20"}
                />
              </div>
            </div>


          </div>

          {alert.message &&
            alert.screen === userConstants.SCREEN_UPDATE_PROFILE && (
              <Alert
                alert={alert}
                onComplete={() =>
                  history.push("/" + authentication.role + "/more/profile")
                }
              />
            )}

          <div className="form-sunE-button btn-save text-right">
            <button
              className={
                "btn-line-blue" +
                (!validateParam() || !isEmpty(feildInputInValid)
                  ? " btn-disable"
                  : "")
              }
            >
              Lưu
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export { EditProfile };
