import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { teacherActions } from "../../../_actions";

function Wish(props) {
  let { lesson_id, wish, onChangeStatus, lesson_type, curriculum_id } = props;
  const dispatch = useDispatch();
  const authentication = useSelector((state) => state.authentication);

  const [statusWish, setStatusWish] = useState(wish ? true : false);

  function addWish(e) {
    e.stopPropagation();
    dispatch(
      teacherActions.addWish({
        lesson_id,
        user_id: authentication.id,
        lesson_type,
        curriculum_id: curriculum_id
      })
    );
    setStatusWish(true);
  }

  function removeWish(e) {
    e.stopPropagation();
    dispatch(
      teacherActions.removeWish({
        exercise_id: lesson_id,
        user_wish_id: authentication.id,
      })
    );
    setStatusWish(false);
    onChangeStatus && onChangeStatus();
    // console.log(onChangeStatus);
  }

  useEffect(() => {
    setStatusWish(wish ? true : false)
  }, [wish])

  return (
    <div className={"like-img flex-m " + (statusWish ? "is_in_wishlist" : "")}>
      <div className="flex-1"></div>
      <div>
        <img
          src="/assets/images/teacher/giaobai/ico_heart.png"
          alt="ico_heart"
          className="wh27 like"
          onClick={(e) => removeWish(e)}
          style={{ cursor: "pointer" }}
        />
        <img
          src="/assets/images/teacher/giaobai/ico_heart_no.png"
          alt="ico_heart_no"
          className="wh27 no_like"
          onClick={(e) => addWish(e)}
          style={{ cursor: "pointer" }}
        />
      </div>
    </div>
  );
}

export { Wish };
