import { Fragment, useEffect, useRef, useState } from "react";
import { Wish } from "../../../_components/Admin/Teacher";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { teacherService } from "../../../_services";
import { curriculumConstants, teacherConstants } from "../../../_constants";
import { TypeAssignExercise } from "../../../_constants/typeAssignExercise";
import _ from "lodash";
import DoTestExam from "../../StudentPage/exam-test/DoTestExam";
import { floatObjectToArray } from "../../../_base/Validate";
import { apiCaller } from "../../../_helpers";
import { isEmpty, findIndex } from "lodash";
import { formatExamDataQuestions } from "../../../_helpers/formatDataLesson";
import { alertActions } from "../../../_actions";
import { ReviewExam } from "../../StudentPage/exam-test/ReviewExam";
import { toggleSearchParam } from "../../../_helpers/utils";
import { Mydata } from "../../../_base/Mydata";

export default function LessonTopic({data, getLessonTopic,fetchMoreLesson, unitId, topicId , curriculumId, typeAssign, userID, setSaveListExercise, isLoading, classId, skill}) {
  const history = useHistory();
  const search = history?.location?.search;
  const params = new URLSearchParams(search);
  const fromPage = params?.get("page");

  const authentication = useSelector((state) => state.authentication);
  const exercises = useSelector((state) => state.classes.exercises);
  const curriculums = useSelector((state) => state.curriculums);
  const dataAssignProposal = useSelector(
    (state) => state.classes.data_assign_proposal
  );

  dataAssignProposal.map(
    (item) =>
      (item.exercise_suggest = floatObjectToArray(item.exercise_suggest))
  );

  const [dataProposal, setDataProposal] = useState(dataAssignProposal);
  
  const dispatch = useDispatch();
  const [listExerciseId, setListExerciseId] = useState(exercises.data_exercise);
  const [examSelected, setExamSelected] = useState()
  const [listProvince, setListProvince] = useState([])
  const [isShowReview, setIsShowReview] = useState(false);
  const [dataQuestion, setDataQuestion] = useState([]);
  const [infoQuestion, setInfoQuestion] = useState();
  const ref = useRef(null);

  const handleReview = (item) => {
    console.log("=====authentication", authentication.hasticket)
    if(authentication.hasticket?.ticket < 1){
      window.alert("Bạn không thể truy cập nội dung này. Vui lòng nâng cấp tài khoản để tiếp tục!");
      return
    }
    getDataExam(item);
  }

  const onClickbox = (item) => {
    console.log("=====authentication", authentication.hasticket)
    if(authentication.hasticket?.ticket < 1){
      window.alert("Bạn không thể truy cập nội dung này. Vui lòng nâng cấp tài khoản để tiếp tục!");
      return
    }
    setExamSelected(item)
  }

  const getDataExam = (item) => {
    const url = `/student/api_exam/exam_v2?id=${item?.id}&type=${item?.type}&class_id=${exercises?.class_id||0}&unit_id=${unitId}&is_homework=0&curriculum_id=${curriculumId}`;
    apiCaller(url, 'GET')
    .then(res=>{
        if(res.status){
          const convertDataQuestion = formatExamDataQuestions(res)
          setDataQuestion(convertDataQuestion);
          setInfoQuestion(res?.lesson);
          setIsShowReview(true)
        } else {
          dispatch(
            alertActions.error({
              message: res?.msg?.toString(),
              screen: '',
              isShowPopup: true
            })
          );
        }
    })
    .catch (err => {
      dispatch(
        alertActions.error({
          message: err?.toString(),
          screen: '',
          isShowPopup: true
        })
      );
    })
  }

  const handleScroll = (e) => {
    if (e.target.clientHeight + e.target.scrollTop + 5 >= e.target.scrollHeight) {
      fetchMoreLesson();
    }
  }

  function handleChecked(data) {
    var index = [
      TypeAssignExercise.DEMAND,
      TypeAssignExercise.CAPACITY,
    ].includes(typeAssign)
      ? listExerciseId?.findIndex(item => (item?.lesson_id == data?.id) && item?.user_id == userID)
      : listExerciseId?.findIndex(item => item?.lesson_id == data?.id)

    if (index === -1) {
      const curriculumsDetail = curriculums?.data?.find(item => item?.id == curriculumId)
      const unitDetail = curriculums?.detail?.data?.find(item => item?.unit_id == unitId)
      let parent = [];
      parent[0] = curriculumsDetail?.name
      parent[1] = unitDetail?.unit_name
      let data_exercise = {
        lesson_id: data?.id,
        exercise_id: data?.id,
        list_guide_id: [],
        exercise_type: data?.type,
        parent: parent,
        detail: {
          ...data,
          unit_id: unitId,
          unit_name: unitDetail?.unit_name,
          lesson_name: data?.name,
          lesson_id: data?.id,
          curriculum_id: curriculumId,
          lesson_type: data?.type,
        },
        curriculum_name: curriculumsDetail?.name,
        lesson_name: data?.name,
        user_id: userID,
        total_time: data?.total_time || 0,
      };
      const dataWithParent = data;
      dataWithParent.curriculum_name = curriculumsDetail?.name;

      listExerciseId.push(data_exercise);
      // listExercisesSelected.push(dataWithParent);
    } else {
      listExerciseId.splice(index, 1);
      // listExercisesSelected.splice(index, 1);
    }
    setSaveListExercise && setSaveListExercise([...listExerciseId]);
    setListExerciseId([...listExerciseId]);
    handleSaveDataLesson(true);
    dispatch({
      type: teacherConstants.ADD_DATA_EXERCISE,
      data: {
        ...exercises,
        data_exercise: listExerciseId,
      },
    });
  }

  const handleSaveDataLesson = (changeExercise) => {
    if (
      typeAssign &&
      [TypeAssignExercise.DEMAND, TypeAssignExercise.CAPACITY].includes(
        typeAssign
      )
    ) {
      // Get DATA of Selected Student
      const studentLessonAdd = dataProposal.filter(
        (item) => item.user_id == userID
      );

      const [objStudentLessonAdd] = studentLessonAdd;

      // Get date exercise_suggest after checked action
      const listExerciseUser = listExerciseId.filter(
        (item) => item.user_id == userID
      );

      const detailFilterNewArray = listExerciseUser?.map(
        (item) => item?.detail ?? item
      );

      objStudentLessonAdd["exercise_suggest"] = detailFilterNewArray;
      if(changeExercise) {
        objStudentLessonAdd["isRoot"] = false;
      }

      const newDataProposal = dataProposal;
      newDataProposal.forEach((item, index, dataOriginal) => {
        if (item.user_id == objStudentLessonAdd.user_id) {
          dataOriginal[index] = objStudentLessonAdd;
        }
      });

      dispatch({
        type: teacherConstants.GET_DATA_ASSIGN_SPECIFIC,
        proposal: newDataProposal,
      });
      // history.replace(
      //   "/" +
      //     authentication.role +
      //     "/class/view/" +
      //     exercises.class_id +
      //     "/exercise/student/" +
      //     typeAssign +
      //     "/list_proposals",
      //   { userIDSelected: userID }
      // );
    }
  };

  const handleSubmit = () => {
    if(_.isEmpty(listExerciseId)) {
      return;
    }
    exercises.data_exercise = listExerciseId;
      if (screen === "CURRICULUM_PERSONAL_FAVORITE") {
        dispatch({
          type: teacherConstants.ADD_DATA_EXERCISE,
          data: { ...exercises, students: [] },
        });
        dispatch({
          type: teacherConstants.SOURCE_PAGE_ADD_EXCERCISE,
          sourcePageAddExcercise: "PAGE_CURRICULUM",
        });
        fromPage == "curriculum"
          ? history.push(
              `/teacher/curriculum/exercise/class` +
                (!_.isEmpty(fromPage) ? `?page=${fromPage}` : "")
            )
          : history.push(`/teacher/curriculum/exercise/class`);
      } else if (!_.isEmpty(exercises.students)) {
        if (
          typeAssign &&
          [TypeAssignExercise.DEMAND, TypeAssignExercise.CAPACITY].includes(
            typeAssign
          )
        ) {
          // Get DATA of Selected Student
          handleSaveDataLesson();
          history.replace(
            "/" +
              authentication.role +
              "/class/view/" +
              exercises.class_id +
              "/exercise/student/" +
              typeAssign +
              "/list_proposals",
            { userIDSelected: userID }
          );
        } else {
          if (fromPage == "curriculum") {
            history.push(
              "/teacher/curriculum/exercise/class" +
                (!_.isEmpty(fromPage) ? `?page=${fromPage}` : "")
            );
          } else {
            history.push(
              "/" +
                authentication.role +
                "/class/view/" +
                exercises.class_id +
                `/exercise/review` +
                (!_.isEmpty(fromPage) ? `?page=${fromPage}` : "")
            );
          }
        }
      } else {
        if (fromPage == "curriculum") {
          history.push(
            "/" +
              authentication.role +
              "/curriculum/exercise/class" +
              (!_.isEmpty(fromPage) ? `?page=${fromPage}` : "")
          );
        } else {
          history.push("/teacher/curriculum/exercise/class");
        }
        dispatch({
          type: teacherConstants.SOURCE_PAGE_ADD_EXCERCISE,
          sourcePageAddExcercise: "PAGE_CURRICULUM",
        });
      }
  }

  const getProvinceList = () => {
    apiCaller(
      "/web_advertise/api_lecture/list_address",
      "GET",
    ).then((res) => {
      if (res.status) {
        setListProvince(res.list_address);
      }
    });
  };

  useEffect(() => {
      getProvinceList()
  }, [])

  return (
    <Fragment>
      <div className='sunE-container-box giaotrinh filter pad-t-0 flex-1'>
        <div
          className={`sunE-giaotrinh-resuft-filter box-shadow scrollbar-custom flex-1 ldjas`}
          onScroll={(e) => {handleScroll(e)}}
          ref={ref}
        >
          {!isLoading && !data?.length && <p style={{color: 'red'}}>Không có bài nào</p>}
          {data?.map((item, index) => {
            let score = item.learned
            ? (item.score || item.number_score) !== undefined
              ? (item.score || item.number_score) + ""
              : "___"
            : "___";
            return (
              <Fragment key={index}>
                <div
                  className='sunE-giaotrinh-item flex-m rel pointer_cursor'
                >
                  <div className='img'>
                    <img
                      src={
                        "/assets/images/teacher/giaobai/" +
                        item?.type?.toLocaleLowerCase() +
                        ".png"
                      }
                      alt={item?.type}
                    />
                  </div>
                  <div
                    className='content rel flex-1'
                    style={{paddingTop: 0}}
                    onClick={() => onClickbox(item)}
                  >
                    <h2 className='title two-line'>
                      {item?.topic}
                    </h2>
                    <h3 className='desc two-line'>
                      {item?.name}
                    </h3>
                    {!!item?.year && <p>{`Năm học: ${item?.year}`}</p>}
                    {!!item?.address_id && <p style={{margin: 0}}>{listProvince?.find(province => province?.id == item?.address_id)?.address}</p>}
                    <div className="flex flex-m" style={{gap: 12}}>
                      {authentication?.role === 'teacher' && <span 
                        className="review-lesson-text"
                        style={{margin: 0, lineHeight: 'unset'}}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleReview(item)
                        }}
                      >
                        Xem đáp án
                      </span>}
                      <div className='chk-gr' style={{alignSelf: 'flex-end'}}>
                        {authentication?.role === 'teacher' && <Wish
                          lesson_id={item?.id}
                          lesson_type={item?.type}
                          curriculum_id={curriculumId}
                          wish={item?.is_in_wishlist}
                          unit_id={unitId}
                        />}
                        <span
                          className='chk-custom-gr size-27 top-1'
                          style={{margin: '0 0 0 5px', display: authentication?.role === 'teacher' ? 'block' : 'none'}}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleChecked(item)
                          }}
                        >
                          <input
                            type='checkbox'
                            defaultChecked={
                              [
                                TypeAssignExercise.DEMAND,
                                TypeAssignExercise.CAPACITY,
                              ].includes(typeAssign)
                                ? _.findIndex(listExerciseId, {
                                    exercise_id: item?.id,
                                    user_id: userID,
                                  }) !== -1 ||
                                  _.findIndex(listExerciseId, {
                                    lesson_id: item?.id,
                                    user_id: userID,
                                  }) !== -1
                                  ? true
                                  : false
                                : _.findIndex(listExerciseId, {
                                    exercise_id: item?.id,
                                  }) !== -1 ||
                                  _.findIndex(listExerciseId, {
                                    lesson_id: item?.id,
                                  }) !== -1
                                ? true
                                : false
                            }
                            checked={
                              [
                                TypeAssignExercise.DEMAND,
                                TypeAssignExercise.CAPACITY,
                              ].includes(typeAssign)
                              ? !!listExerciseId?.find(
                                (exercise) =>
                                  (exercise?.lesson_id === item?.id) && exercise?.user_id == userID
                              )
                              : !!listExerciseId?.find(
                                (exercise) =>
                                  exercise?.lesson_id === item?.id
                              )
                            }
                            disabled
                            id={item?.id}
                          />
                          <label htmlFor={item?.id}></label>
                        </span>
                      </div>
                    </div>
                    <div className='flex-m history'>
                        <div className='flex-1'>
                          {item.lesson_type !== "skill_guide" &&
                            item.learned && (
                              <Link
                                className='link-to-url'
                                to={
                                  "/" +
                                  authentication.role +
                                  `/curriculum/${classId}/skill/${unitId}/lesson/${item.type}/${
                                    item.id
                                  }/history${
                                    `?tittle=${skill}&topicId=${topicId}&page=${2}`
                                  }${!!curriculumId ? `&curriculumId=${curriculumId}` : ''}`
                                }
                              >
                                {"Lịch sử làm bài"}
                              </Link>
                            )}
                        </div>
                        {authentication?.role !== 'teacher' && item.lesson_type !== "skill_guide" && (
                          <div className='scre'>
                            <p className='bold'>Điểm: {score}</p>
                          </div>
                        )}
                      </div>
                  </div>
                </div>
              </Fragment>
            )
          })}
        </div>
      </div>
      {!!data?.length && authentication?.role === 'teacher' && (
        <div className='form-sunE-button text-right father_btn_assign_assignments'>
          <button
            className={
              "responsive_btn_assign_assignments btn-bg-blue btn-default pos_rel" +
              (_.isEmpty(listExerciseId) ? " btn-disable" : "")
            }
            onClick={handleSubmit}
          >
            <div className='pos_abs quantity_from_teacher_choose'>
              {[
                TypeAssignExercise.DEMAND,
                TypeAssignExercise.CAPACITY,
              ].includes(typeAssign)
                ? listExerciseId?.filter((item) => item.user_id == userID)
                    .length || 0
                : listExerciseId?.length || 0}
            </div>
            Giao bài
          </button>
        </div>
      )}
      {!!examSelected && (
        <DoTestExam
          setExamSelected={setExamSelected}
          isShow={examSelected !== null}
          onClose={() => {
            setExamSelected(null); 
            const url = new URL(window.location.href);
            const params = new URLSearchParams(url.search);
            if (!!params.get('isDidExercise')) {
              toggleSearchParam({
                key: 'isDidExercise'
              })
              getLessonTopic && getLessonTopic()
            }
          }}
          mockTestId={
            examSelected?.id
          }
          type={examSelected?.type}
          examSelected={examSelected}
          heading={examSelected?.name || ''}
          classId={classId}
        />
      )}
      <ReviewExam
        examInfo={infoQuestion}
        isShow={isShowReview}
        onClose={() => setIsShowReview(false)}
        listQuestions={dataQuestion}
        isShowResultLesson={false}
        isReview={true}
      />
    </Fragment>
  )
}