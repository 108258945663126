// import XLSX from "sheetjs-style";
import * as XLSX from 'xlsx'
export const convertSkillVN = (skill) => {
  switch (skill) {
          case 'vocabulary':
              return "Học từ vựng";
          case 'listening':
              return "Luyện nghe";
          case 'grammar':
              return "Học ngữ pháp";
          case 'speaking':
              return "Luyện nói";
          case 'reading':
              return "Luyện đọc";
          case 'writing':
              return "Luyện viết";
          case 'pronunciation':
              return "Học phát âm";
          case 'mini_test':
              return "Kiểm tra";
          case 'exam':
              return "Kiểm tra";
          case 'project':
              return "Bài tập lớn";          
      default:
          return ""
  }
}

export const convertSingleQuote = (str) => {
    if(typeof str !== 'string') return str;
    return str.replaceAll("‘", "'").replaceAll("’", "'").replaceAll("‛", "'");
}

export function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
  }

export const toListDownLine = (string) => {
    var str
    try {
      str = string.replace('\t', ' ');
    } catch (error) {
      str = string
    }
    var strList = str.split('\n')
    return strList
}

export const validWord = (s) => {
    if (!s || s.length == 0 || typeof s !== 'string') {
      return s;
    }
    let res = s
      .toLowerCase()
      .replace(/[‘\u2018\u2019]/g, "'")
      .replace(/“|”/g, '"')
      .replace(/\s+/g, ' ')
      .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]+$/g, "")
      .trim();
    while (res.indexOf('  ') > -1) {
      res = res.replace(/ {2}/g, ' ');
    }
    while (
      res.charAt(res.length - 1) === '.' ||
      res.charAt(res.length - 1) === ','
    ) {
      res = res.substring(0, res.length - 1).trim();
    }
    return res.trim();
  };

  export const validOnlyWord = (str) => {
    return removeSpecialWord(validWord(str))
  }

  const removeSpecialWord = (string) => {
    return string.replace('?','').replace('.','').replace(',','').replace('!','')
  }

  export const capitalize = (str) => {
    if(!str) return ''
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  export const isValidUrl = urlString=> {
    var urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
  '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
  '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
  '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
  return !!urlPattern.test(urlString);
  }

  export function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }

  export function hasScroll(el, direction) {
    if (!el) {
      return false;
    };
    direction = (direction === 'vertical') ? 'scrollTop' : 'scrollLeft';
    let result = !! el?.[direction];

    if (!result) {
        el[direction] = 1;
        result = !!el?.[direction];
        el[direction] = 0;
    }
    return result;
}

export const toggleSearchParam = ({key, value}) => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  if (!!params.get(key)) {
    params.delete(key);
  } else {
    params.append(key, value);
  }
  const newUrl = url.origin + url.pathname + (!!params.toString() ? ('?' + params.toString()) : '');
  window.history.replaceState({}, '', newUrl)
}

const listAlphabet = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

// export const exportExcel = (listHeader = [], listData = [], fileName = "") => {
//   const workbook = XLSX.utils.book_new();
//   const ws = XLSX.utils.aoa_to_sheet([listHeader, ...listData]);
//   listHeader.map((item, index) => {
//     ws[`${listAlphabet[index]}1`].s = {
//       font: {
//         bold: true,
//       },
//     };
//   });
//   XLSX.utils.book_append_sheet(workbook, ws, "SheetName");
//   XLSX.writeFile(workbook, fileName);
// };

// listHeader = {label: string; width: number}[]
// listData = string[]
export const exportExcel = (listHeader = [], listData = [], fileName = "") => {
  const ws = XLSX.utils.aoa_to_sheet([listHeader?.map(item => item?.label), ...listData]);
  /* set column metadata */
  ws["!cols"] = [];
  listHeader?.map((item, i) => {
    const r = {};
    (ws["!cols"][i] = r).wpx = item?.width;
  })
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
  XLSX.writeFile(wb, `${fileName}.xlsx`, {cellStyles: true});
}