import React, { useState } from "react";
import { useEffect } from "react";
import { studentService } from "../../../_services";
import {
  ExamGrammar1,
  ExamGrammar9,
  ExamPro1,
  ExamReading1,
} from "../../../_components/exam-test";
import { ExamListening } from "../../../_components/exam-test/ExamListening";
import { ExamWriting } from "../../../_components/exam-test/ExamWriting";
import { TimerTick } from "./DoTestExam";
import "./styles.scss";
import { ModalOverviewExam } from "./ModalOverview";
import { convertSingleQuote, isJsonString, validWord } from "../../../_helpers/utils";
import ResultQuestionFillTheBlank from "../resultLesson/ResultQuestionFillTheBlank";
import ResultQuestionMultipleChoice from "../resultLesson/ResultQuestionMultipleChoice";
import { AudioCustom } from "../../../_components/Audio";
import ResultAnswerTheQuestion from "../resultLesson/ResultAnswerTheQuestion";
import ResultQuestionTrueFalse from "../resultLesson/ResultQuestionTrueFalse";
import ResultRewriteWithTheGivenWords from "../resultLesson/ResultRewriteWithTheGivenWords";
import ResultQuestionFillTheSentence from "../resultLesson/ResultQuestionFillTheSentence";
import ResultQuestionChooseLetters from "../resultLesson/ResultQuestionChooseLetters";
import ResultQuestionSpeaking from "../resultLesson/ResultQuestionSpeaking";
import ResultQuestionMatching from "../resultLesson/ResultQuestionMatching";
import ResultQuestionChooseSound from "../resultLesson/ResultQuestionChooseSound";
import ResultQuestionRearrange from "../resultLesson/ResultQuestionRearrange";
import ResultQuestionTrueFalseWriting from "../resultLesson/ResultQuestionTrueFalseWriting";
import ResultQuestionChooseHeading from "../resultLesson/ResultQuestionChooseHeading";
import ResultQuestionPickGroup from "../resultLesson/ResultQuestionPickGroup";
import ResultQuestionWritingPassage from "../resultLesson/ResultQuestionWritingPassage";
import ResultQuestionChooseChoice from "../resultLesson/ResultQuestionChooseChoice";
import ResultQuestionPronun8 from "../resultLesson/ResultQuestionPronun8";
import ResultQuestionVocabulary from "../resultLesson/ResultQuestionVocabulary";
import ResultQuestionProject from "../resultLesson/ResultQuestionProject";
import { configConstants } from "../../../_constants";

export const ReviewExam = (props) => {
  const { isShow, onClose, listQuestions, resultExam, examInfo, isShowResultLesson, isReview, resultLesson, isReviewByNumberQuestion = false } = props;
  const [currentCategory, setCurrentCategory] = useState(0);
  const [indexQuestion, setIndexQuestion] = useState(1);
  const [isShowOverview, showOverview] = useState(false);
  const [listReview, setListReview] = useState([])

  const [questionSelected, setQuestionSelected] = useState({
    detail_user_turn: [],
    exercise_type: "",
    final_user_choice: "",
    question_id: "",
    question_score: "",
    question_type: "",
  });

  useEffect(() => {
    if (isShow) {
      setCurrentCategory(0);
      setIndexQuestion(1);
    }
  }, [isShow]);

  useEffect(() => {
    let element = document.getElementById(questionSelected?.question_id);
    if (questionSelected?.isDivideQues) {
      element = document.getElementById('question-' + questionSelected.questionNumber);
    }
    element?.scrollIntoView();
  }, [questionSelected, currentCategory]);

  useEffect(() => {
    getListReview()
  }, [listQuestions])

  const getListReview = () => {
    const listRv = []
    listQuestions?.forEach((category) => {
      category?.listQuestions?.forEach((question) => { 
        const isDivideQues = question?.list_option?.length > 1 && (
          (question?.skill == 'reading' && question?.question_type == 2) ||
          (question?.skill == 'reading' && question?.question_type == 3) ||
          (question?.skill == 'reading' && question?.question_type == 4)
        )
        let answer = resultExam?.find(
          (item) => item.questionId == question.question_id
        )?.answer;
        let question_score = "0";
        if (
          ["grammar1", "reading14", "listening9"].includes(
            question.skill + question.question_type
          )
        ) {
          answer = convertSingleQuote(typeof answer === 'object' ? answer?.find((item) => item.type == "input").value?.trim() : answer?.trim());
        }
        if (
          [
            "grammar1",
            "reading14",
            "listening2",
            "listening9",
            "reading2",
            "reading3",
            "reading4",
            "reading11",
            "writing2",
            "writing3",
            "writing4",
            "writing5",
            "listening1",
          ].includes(question.skill + question.question_type)
        ) {
          let matchOptionText = question?.list_option[0].match_option_text?.map(
            (text) => convertSingleQuote(text.toLowerCase())
          );
          if (question.skill + question.question_type == "writing2") {
            matchOptionText = [
              convertSingleQuote(question?.list_option[0].question_content
              ?.replaceAll("/", "")
              ?.replace(/  +/g, ' ')
              ?.toLowerCase()
              ?.trim())
            ];
          }

          if (
            matchOptionText?.includes(
              convertSingleQuote(answer
                ?.toLowerCase()
                ?.trim()
                ?.replace(/[^A-Za-z0-9]+$/, "")
                ?.replace(/\s+/g, " "))
            ) ||
            matchOptionText?.includes(
              convertSingleQuote(answer
                ?.toLowerCase()
                ?.trim()
                ?.replace(/[^A-Za-z0-9]+$/, "")
                ?.replace(/\s+/g, " ")
                ?.concat("."))
            ) ||
            matchOptionText?.includes(
              convertSingleQuote(answer
                ?.toLowerCase()
                ?.trim()
                ?.replace(/[^A-Za-z0-9]+$/, "")
                ?.replace(/\s+/g, " ")
                ?.concat("?"))
            ) || matchOptionText?.find(item => validWord(item) === validWord(answer))
          ) {
            question_score = question.list_option[0].score;
          }
        } else {
          let option = question?.list_option?.find((item) =>
            // item?.match_option_text?.includes(answer)
          item?.match_option_text?.find(item => validWord(item) === validWord(answer))
          );
          question_score = option?.score || "0";
        }
        let questionNumber = 1
        question?.list_option?.map(option => {
          if (isDivideQues) {
            listRv.push({
              question_id: question?.question_id,
              questionNumber,
              checked: false,
              isDidExam: false,
              isDivideQues: true
            })
            questionNumber += 1
          }
          return {
            ...option,
            match_option_text: typeof option?.match_option_text === "string" ? convertSingleQuote(option?.match_option_text) : option?.match_option_text?.map(item => {
              if (typeof item === 'string') {
                return convertSingleQuote(item)
              }
              return item;
            })
          }
        })
        if (!isDivideQues) { 
          listRv.push({
            question_id: question?.question_id,
            questionNumber,
            checked: false,
            isDidExam: false,
            isDivideQues: false,
            score: question_score
          })
          questionNumber += 1
        }
      })
    })
    setListReview(listRv)
  }

  if (!isShow) {
    return null;
  }

  const backCategory = () => {
    if (currentCategory > 0) {
      setIndexQuestion(
        indexQuestion - listQuestions[currentCategory - 1].listQuestions?.length
      );
      setCurrentCategory(currentCategory - 1);
    }
  };

  const nextCategory = () => {
    if (currentCategory < listQuestions.length - 1) {
      setIndexQuestion(
        indexQuestion + listQuestions[currentCategory].listQuestions?.length
      );
      setCurrentCategory(currentCategory + 1);
    }
  };

  const formatListAnswer = () => {
    let listAnswers = [];
    listQuestions?.forEach((category) => {
      category?.listQuestions?.forEach((question) => {
        let answer = resultExam?.find(
          (item) => item.questionId == question.question_id
        )?.answer;
        let question_score = "0";
        if (
          ["grammar1", "reading14", "listening9"].includes(
            question.skill + question.question_type
          )
        ) {
          answer = convertSingleQuote(typeof answer === 'object' ? answer?.find((item) => item.type == "input").value?.trim() : answer?.trim());
        }
        if (
          [
            "grammar1",
            "reading14",
            "listening2",
            "listening9",
            "reading2",
            "reading3",
            "reading4",
            "reading11",
            "writing2",
            "writing3",
            "writing4",
            "writing5",
            "listening1",
          ].includes(question.skill + question.question_type)
        ) {
          let matchOptionText = question?.list_option[0].match_option_text?.map(
            (text) => convertSingleQuote(text.toLowerCase())
          );
          if (question.skill + question.question_type == "writing2") {
            matchOptionText = [
              convertSingleQuote(question?.list_option[0].question_content
              ?.replaceAll("/", "")
              ?.replace(/  +/g, ' ')
              ?.toLowerCase()
              ?.trim())
            ];
          }

          if (
            matchOptionText?.includes(
              convertSingleQuote(answer
                ?.toLowerCase()
                ?.trim()
                ?.replace(/[^A-Za-z0-9]+$/, "")
                ?.replace(/\s+/g, " "))
            ) ||
            matchOptionText?.includes(
              convertSingleQuote(answer
                ?.toLowerCase()
                ?.trim()
                ?.replace(/[^A-Za-z0-9]+$/, "")
                ?.replace(/\s+/g, " ")
                ?.concat("."))
            ) ||
            matchOptionText?.includes(
              convertSingleQuote(answer
                ?.toLowerCase()
                ?.trim()
                ?.replace(/[^A-Za-z0-9]+$/, "")
                ?.replace(/\s+/g, " ")
                ?.concat("?"))
            )
          ) {
            question_score = question.list_option[0].score;
          }
        } else {
          let option = question?.list_option?.find((item) =>
            item?.match_option_text?.includes(answer)
          );
          question_score = option?.score || "0";
        }
        listAnswers.push({
          exercise_type: question.skill,
          final_user_choice: "",
          question_id: question.question_id,
          question_score: question_score,
          question_type: question.question_type,
          detail_user_turn: [
            {
              num_turn: 1,
              score: question_score,
              user_choice: answer,
            },
          ],
        });
      });
    });
    return listAnswers;
  };

  const onShowQuestion = (question) => {
    let sttQuestion = 1;
    setQuestionSelected(question);
    showOverview(false);

    // listQuestions?.forEach((category, index) => {
    //   category?.listQuestions?.forEach((item) => {
    //     if (item.question_id == question.question_id) {
    //       setCurrentCategory(index);
    //       showOverview(false);
    //       setIndexQuestion(sttQuestion);
    //     }
    //   });
    //   sttQuestion = sttQuestion + category.listQuestions?.length;
    // });
  };

  const renderQuestion = (category) => {
    const indexQuestion = category?.listQuestions?.[0]?.questionNumber || 1
    const skill = category?.skill;
    const question_type = category?.question_type;
    if (isShowResultLesson) {
      const userChoiceQuestion = JSON.stringify(resultLesson?.map(item => isJsonString(item?.user_choice) ? JSON.parse(item?.user_choice) : item?.user_choice)?.flat()) || `[{"num_turn":1,"score":0,"user_choice":""}]`
      let listJammingAnswer = !!props?.listJammingAnswer?.length ? props?.listJammingAnswer : (category?.listQuestions?.length > 1  
              ? category?.listQuestions?.map(item => item?.list_option?.[0]?.jamming_answer_parse)?.flat()
              : category?.listQuestions?.[0]?.list_option?.map(item => item?.match_option_text)?.flat()); // reading 3: jamming_answer_parse has same item
      category?.listQuestions?.[0]?.list_option?.map(item => {
        item?.jamming_answer_parse?.map(jamming => {
          if(!listJammingAnswer?.find(text => text === jamming)) {
            listJammingAnswer?.push(jamming)
          }
        })
      })
      if(
        (skill == 'reading' && question_type == 2) ||
        (skill == 'reading' && question_type == 3) ||
        (skill == 'reading' && question_type == 4) ||
        (skill == 'reading' && question_type == 10) ||
        (skill == 'reading' && question_type == 13) ||
        (skill == 'grammar' && question_type == 1) ||
        (skill == 'grammar' && question_type == 4) ||
        (skill == 'grammar' && question_type == 8) ||
        (skill == 'grammar' && question_type == 12) ||
        (skill == 'grammar' && question_type == 13) ||
        (skill == 'pronunciation' && question_type == 9) ||
        (skill == 'listening' && question_type == 4) ||
        (skill == 'listening' && question_type == 8)
      ) {
          return (
              <ResultQuestionFillTheBlank
                dataQuestion={category?.listQuestions}
                question_type={question_type}
                skill={skill}
                answer={userChoiceQuestion}
                listJammingAnswer={listJammingAnswer}
                infoQuestion={examInfo}
                numberReview={props?.numberReview}
              />
          )
      }
      if(
        (skill == 'reading' && question_type == 1) ||
        (skill == 'reading' && question_type == 6) ||
        (skill == 'reading' && question_type == 15) ||
        (skill == 'pronunciation' && question_type == 2) ||
        (skill == 'pronunciation' && question_type == 5) ||
        (skill == 'pronunciation' && question_type == 6) ||
        (skill == 'pronunciation' && question_type == 10) ||
        (skill == 'pronunciation' && question_type == 11) ||
        (skill == 'grammar' && question_type == 9) ||
        (skill == 'listening' && question_type == 3)
      ) {
          return (
              <ResultQuestionMultipleChoice
                dataQuestion={category?.listQuestions}
                question_type={question_type}
                skill={skill}
                answer={userChoiceQuestion}
                infoQuestion={examInfo}
                numberReview={props?.numberReview}
              />
          )
      }
      if (
        (skill == 'reading' && question_type == 11) ||
        (skill == 'writing' && question_type == 2) ||
        (skill == 'writing' && question_type == 3) ||
        (skill == 'writing' && question_type == 5) ||
        (skill == 'listening' && question_type == 1) ||
        (skill == 'listening' && question_type == 2) ||
        (skill == 'grammar' && question_type == 5) ||
        (skill == 'grammar' && question_type == 6) ||
        (skill == 'grammar' && question_type == 7) ||
        (skill == 'grammar' && question_type == 10)
      ) {
          return (
              <ResultAnswerTheQuestion
                dataQuestion={category?.listQuestions}
                question_type={question_type}
                skill={skill}
                answer={userChoiceQuestion}
                infoQuestion={examInfo}
                numberReview={props?.numberReview}
              />
          )
      }
      if (
        (skill == 'reading' && question_type == 12) ||
        (skill == 'listening' && question_type == 5)
      ) {
          return (
              <ResultQuestionTrueFalse
                dataQuestion={category?.listQuestions}
                question_type={question_type}
                skill={skill}
                answer={userChoiceQuestion}
                infoQuestion={examInfo}
                numberReview={props?.numberReview}
              />
          )
      }
      if (
        (skill == 'writing' && question_type == 4)
      ) {
          return (
              <ResultRewriteWithTheGivenWords
                dataQuestion={category?.listQuestions}
                question_type={question_type}
                skill={skill}
                answer={userChoiceQuestion}
                infoQuestion={examInfo}
                numberReview={props?.numberReview}
              />
          )
      }
      if (
        (skill == 'reading' && question_type == 14) ||
        (skill == 'listening' && question_type == 9)
      ) {
          return (
              <ResultQuestionFillTheSentence
                dataQuestion={category?.listQuestions}
                question_type={question_type}
                skill={skill}
                answer={userChoiceQuestion}
                infoQuestion={examInfo}
                numberReview={props?.numberReview}
              />
          )
      }
      if (
        (skill == 'pronunciation' && question_type == 3) ||
        (skill == 'pronunciation' && question_type == 7) ||
        (skill == 'listening' && question_type == 7)
      ) {
          return (
              <ResultQuestionChooseLetters 
                dataQuestion={category?.listQuestions}
                question_type={question_type}
                skill={skill}
                answer={userChoiceQuestion}
                infoQuestion={examInfo}
                numberReview={props?.numberReview}
              />
          )
      }
      if (
        (skill == 'pronunciation' && question_type == 1) ||
        (skill == 'speaking' && question_type == 2)
      ) {
          return (
              <ResultQuestionSpeaking 
                dataQuestion={category?.listQuestions}
                question_type={question_type}
                skill={skill}
                answer={userChoiceQuestion}
                infoQuestion={examInfo}
                isReview={isReview}
                numberReview={props?.numberReview}
              />
          )
      }
      if (
        (skill == 'writing' && question_type == 1) ||
        (skill == 'listening' && question_type == 6) ||
        (skill == 'grammar' && question_type == 3) ||
        (skill == 'reading' && question_type == 7) 
      ) {
          return (
              <ResultQuestionMatching 
                dataQuestion={category?.listQuestions}
                question_type={question_type}
                skill={skill}
                answer={userChoiceQuestion}
                infoQuestion={examInfo}
                numberReview={props?.numberReview}
              />
          )
      }
      if (
        (skill == 'pronunciation' && question_type == 4)
      ) {
          return (
              <ResultQuestionChooseSound 
                dataQuestion={category?.listQuestions}
                question_type={question_type}
                skill={skill}
                answer={userChoiceQuestion}
                infoQuestion={examInfo}
                numberReview={props?.numberReview}
              />
          )
      }
      if (
        (skill == 'speaking' && question_type == 1)
      ) {
          return (
              <ResultQuestionRearrange 
                dataQuestion={category?.listQuestions}
                question_type={question_type}
                skill={skill}
                answer={userChoiceQuestion}
                infoQuestion={examInfo}
                isReview={isReview}
                numberReview={props?.numberReview}
              />
          )
      }
      if (
        (skill == 'grammar' && question_type == 2)
      ) {
          return (
              <ResultQuestionTrueFalseWriting 
                dataQuestion={category?.listQuestions}
                question_type={question_type}
                skill={skill}
                answer={userChoiceQuestion}
                infoQuestion={examInfo}
                isReview={isReview}
                numberReview={props?.numberReview}
              />
          )
      }
      if (
        (skill == 'reading' && question_type == 8) ||
        (skill == 'reading' && question_type == 9)
      ) {
          return (
              <ResultQuestionChooseHeading 
                dataQuestion={category?.listQuestions}
                question_type={question_type}
                skill={skill}
                answer={userChoiceQuestion}
                infoQuestion={examInfo}
                isReview={isReview}
                numberReview={props?.numberReview}
                listOptionChoose={props?.listOptionChoose}
              />
          )
      }
      if (
        (skill == 'writing' && question_type == 6)
      ) {
          return (
              <ResultQuestionPickGroup
                dataQuestion={category?.listQuestions}
                question_type={question_type}
                skill={skill}
                answer={userChoiceQuestion}
                infoQuestion={examInfo}
                isReview={isReview}
                numberReview={props?.numberReview}
              />
          )
      }
      if (
        (skill == 'writing' && question_type == 7) ||
        (skill == 'speaking' && question_type == 3)
      ) {
          return (
              <ResultQuestionWritingPassage
                dataQuestion={category?.listQuestions}
                question_type={question_type}
                skill={skill}
                answer={userChoiceQuestion}
                infoQuestion={examInfo}
                isReview={isReview}
                numberReview={props?.numberReview}
              />
          )
      }
      if (
        (skill == 'reading' && question_type == 5) ||
        (skill == 'grammar' && question_type == 11)
      ) {
          return (
              <ResultQuestionChooseChoice
                dataQuestion={category?.listQuestions}
                question_type={question_type}
                skill={skill}
                answer={userChoiceQuestion}
                infoQuestion={examInfo}
                isReview={isReview}
                numberReview={props?.numberReview}
              />
          )
      }
      if (
        (skill == 'pronunciation' && question_type == 8)
      ) {
          return (
              <ResultQuestionPronun8 
                dataQuestion={category?.listQuestions}
                question_type={question_type}
                skill={skill}
                answer={userChoiceQuestion}
                infoQuestion={examInfo}
                isReview={isReview}
                numberReview={props?.numberReview}
              />
          )
      }

      if (skill === 'vocabulary') {
        return (
            <ResultQuestionVocabulary 
              dataQuestion={category?.listQuestions}
              answer={userChoiceQuestion}
            />
        )
      }
      if (skill === 'project') {
        return (
            <ResultQuestionProject dataQuestion={category?.listQuestions}/>
        )
      }
    } else {
      if (category.skill === "pronunciation") {
        return (
          <ExamPro1
            isReview={true}
            category={category}
            indexQuestion={indexQuestion}
            resultExam={resultExam}
            onChangeResult={(questionId, answer) => {}}
            numberReview={props?.numberReview}
          />
        );
      } else if (category.skill === "reading") {
        return (
          <ExamReading1
            isReview={true}
            type={"reading" + category?.question_type}
            category={category}
            indexQuestion={indexQuestion}
            resultExam={resultExam}
            onChangeResult={(questionId, answer) => {}}
            numberReview={props?.numberReview}
          />
        );
      } else if (category.skill === "writing") {
        return (
          <ExamWriting
            type={"writing" + category?.question_type}
            isReview={true}
            category={category}
            indexQuestion={indexQuestion}
            resultExam={resultExam}
            onChangeResult={(questionId, answer) => {}}
            numberReview={props?.numberReview}
          />
        );
      } else if (category.skill === "listening") {
        return (
          <ExamListening
            type={"listening" + category?.question_type}
            isReview={true}
            category={category}
            indexQuestion={indexQuestion}
            resultExam={resultExam}
            onChangeResult={(questionId, answer) => {}}
            numberReview={props?.numberReview}
          />
        );
      } else if (category.skill === "grammar") {
        if (category?.question_type === "1") {
          return (
            <ExamGrammar1
              category={category}
              isReview={true}
              indexQuestion={indexQuestion}
              resultExam={resultExam}
              onChangeResult={(questionId, answer) => {}}
              numberReview={props?.numberReview}
            />
          );
        } else if (
          category?.question_type === "9" ||
          category?.question_type === "11"
        ) {
          return (
            <ExamGrammar9
              category={category}
              isReview={true}
              indexQuestion={indexQuestion}
              resultExam={resultExam}
              onChangeResult={(questionId, answer) => {}}
              numberReview={props?.numberReview}
            />
          );
        }
      }
    }
  };

  return (
    <div id="c-do-test-exam">
      <div className="sunE-container rel flex bg-main-img">
        <div className="header-modal">
          <div className="title-header" onClick={onClose}>
            <img src="/assets/images/icon/ico_left.png" />
            <span style={{ marginLeft: 30 }}>
              {props?.heading || `${
                (!["mini_test", "exam", "remind"].includes(
                  examInfo?.lesson_type ||
                    examInfo?.exercise_type ||
                    examInfo?.type
                ) && !isShowResultLesson)
                  ? "Bài thi "
                  : ""
              }${examInfo?.name || examInfo?.exam_name || ""}`}
            </span>
          </div>
          <div className="text-right">
            <button className="btn btn-overview hide">Video chữa bài</button>
            {!isShowResultLesson && !isReviewByNumberQuestion && <button
              className="btn btn-overview"
              onClick={() => showOverview(true)}
            >
              Mục lục
            </button>}
          </div>
        </div>
        <div className="flex-1 scrollbar-custom">
          {/* <div className="questions">
            {listQuestions.length > 0 &&
              renderQuestion(listQuestions[currentCategory])}
          </div> */}
          {listQuestions.length > 0 &&
              listQuestions?.map((item, index) => {
                const audio = examInfo?.lesson_audio ? examInfo.lesson_audio : examInfo?.audio ? (configConstants.BASE_URL + examInfo.audio) : '';
                console.log('audio: ', audio)
                return (
                  <>
                    {isShowResultLesson && <p className="questions title-question title_question_width">{item?.group_content}</p>}
                    {audio && <div className="questions title-question title_question_width" style={{display: 'block'}}><AudioCustom link={audio} /></div>}
                    <div className="questions" key={item?.group_id}>
                      {renderQuestion(item)}
                    </div>
                  </>
              )})
            }
        </div>
        <div className="footer-exam">
          {/* <button
            className="btn btn-primary"
            onClick={backCategory}
            disabled={currentCategory === 0}
          >
            <img src="/assets/images/icon/ico_left_white.png" />
          </button>
          <TimerTick seconds={15 * 60} disabled={true}/>
          <button
            className="btn btn-primary"
            onClick={nextCategory}
            disabled={currentCategory === listQuestions.length - 1}
          >
            <img src="/assets/images/icon/ico_right_white.png" />
          </button> */}
        </div>
      </div>
      {isShowOverview && !isShowResultLesson && (
        <ModalOverviewExam
          isReviewScreen={true}
          open={isShowOverview}
          onClose={() => showOverview(false)}
          // dataAnswers={formatListAnswer()}
          onShowQuestion={onShowQuestion}
          isReviewQuestion={isReview}
          listReview={listReview}
        />
      )}
    </div>
  );
};
