import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import moment from "moment";
import { useParams, Link } from "react-router-dom";
import { teacherActions } from "../../../_actions";
import { curriculumConstants, teacherConstants } from "../../../_constants";
import { Header } from "../../../_components/Admin/Header";
import { PopUpYesNo } from "../../../_components/Popup";
import { Alert } from "../../../_components/Alert";
import { useHistory } from "react-router-dom";
import { teacherService } from "../../../_services";

function HomePageManageCurriculumClass() {
  const { id } = useParams();
  const alert = useSelector((state) => state.alert);
  const history = useHistory();
  const dispatch = useDispatch();
  const [dataClass, setDataClass] = useState([]);
  useEffect(() => {
    dispatch(teacherActions.getDetailClass(id));

    teacherService.getDetailClass(id).then((res) => {
      if (res?.data) {
        setDataClass(res?.data);
        dispatch({
          type: teacherConstants.GET_DETAIL_CLASS,
          class: res.data,
        });
        dispatch({
          type: teacherConstants.SAVE_DATA_CLASS,
          payload: {
            id,
            data: res.data
          }
        })
      }
    });
  }, []);
  const authentication = useSelector((state) => state.authentication);
  const curriculum = useSelector((state) => state.curriculums.detail_class);
  const [showPopup, setShowPopup] = useState(false);
  // console.log(dataClass);

  function removeCurriculum(curriculum_id) {
    setShowPopup(false);
    dispatch(
      teacherActions.removeCurriculum({
        class_id: id,
        curriculum_id: curriculum_id,
      })
    );
  }

  // console.log(dataClass);

  function changeFormatGrade(string) {
    if (string) {
      const [grade, gradeNumber] = string?.split(" ");
      const result = `${grade}: ${gradeNumber}`;
      return result;
    }
  }

  return (
    <div className="sunE-right-container flex-column">
      <Header
        title={"Giáo trình"}
        isBack
        clickBack={() =>
          history.push("/" + authentication.role + "/class/view/" + id)
        }
      />
      {alert.message &&
        alert.screen === curriculumConstants.SCREEN_DETAIL_CURRICULUM && (
          <Alert alert={alert} />
        )}
      {/* {showPopup && (
        <PopUpYesNo
          onClickYes={() => removeCurriculum(curriculum.id)}
          onClickNo={() => setShowPopup(false)}
          message={"Bạn có chắc chắn muốn xoá Giáo trình của lớp?"}
          labelNo={"Không"}
          width={"278px"}
        />
      )} */}
      {!isEmpty(curriculum.id) ? (
        <div className="sunE-container-box giaotrinh canhan flex-1">
          <div className="sunE-giaotrinh-list flex-center-column">
            <div
              style={{ width: "95%" }}
              className="box-giaotrinh-gr box-shadow hei-custom edit flex-m"
            >
              <div className="box-select-info flex-1">
                <p>{curriculum.curriculum_data.name}</p>
                <span>{changeFormatGrade(dataClass?.grade)}</span>
                {curriculum?.curriculum_data?.type !== "personal" && <span>Số lượng Unit: {dataClass.curriculum_unit}</span>}
                <span>
                  Ngày gán:{" "}
                  {moment(dataClass?.curriculum_assign)?.format(
                    "DD/MM/YYYY"
                  )}
                </span>
              </div>
              {/* <div>
                <img
                  src="/assets/images/teacher/ico_remove_blue.png"
                  alt="ico_remove_blue"
                  className="btn-remove"
                  onClick={() => setShowPopup(true)}
                />
              </div> */}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex-1 text-center no-gt">
          <img src="/assets/images/giaotrinh/bg_gt.png" alt="" />
          <p className="bold">
            Lớp chưa có giáo trình nào!
            <br />
            Hãy ấn vào "Thêm giáo trình" để thêm giáo trình cho lớp.
          </p>
        </div>
      )}
      <Link
        to={"/" + authentication.role + "/class/view/" + id + "/curriculum/add"}
      >
        <div className="form-sunE-button text-right mg-20">
          <button className="btn-line-blue">
            {isEmpty(curriculum.curriculum_data)
              ? "Thêm giáo trình"
              : "Thay giáo trình"}
          </button>
        </div>
      </Link>
    </div>
  );
}

export { HomePageManageCurriculumClass };
