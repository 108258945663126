import { configConstants } from "../_constants";
import { apiCaller, apiRequest } from "./../_helpers";

export const studentService = {
  getListDataLearn,
  getHomeWorkByTeacher,
  getListCurriculum,
  getExercise,
  getOfflineAssessment,
  getRankAssessment,
  getLarningHistory,
  getVocabulary,
  getListDataLearnV2,
  getStudentLesson,
  getListCurriculumHistory,
  startDoMasterUnit,
  saveResultMasterUnit,
  getListCurriculumHistoryMasterUnit,
  getListCurriculumMasterUnit,
  getLessonMasterUnit,
  learn_exam,
  getDetailStudyGuild,
  getExamTestService,
  getExamTestHistoryService,
  getDetailExamTestHistoryService,
  startDoExamService,
  saveExamService,
  getDetailExamResult,
  getDetailMockTest,
  getExamTestServiceNews,
  getListCurriculumFirstStep,
  postCurriculumFirstStep,
  getCalenderList,
  postStudySchedule,
  getPlacementTest,
  getResultPlacementTest,
  getVocabularyByClass,
  getVocabularyDetail,
  getMyClassesV2,
  getDetailHomeWorkExercise,
  getInfoStudent,
  loadCurriculumRootLessness,
  cancelEntranceExam,
  getListClassStudentOnline,
  getHomeWorkByParent,
  getListMyParent,
  getListInvitation,
  removeParent,
  acceptInvitationParent,
  checkParent
};

function checkParent(email) {
  return apiCaller(
    `/api_relationship/check_parent?username=${email}`,
    "GET",
    {},
    null,
    true,
    configConstants.API_URL_SETEST,
    true,
    true
  );
}

function acceptInvitationParent(data) {
  return apiCaller(
    `/api_relationship/confirm_relationship`,
    "POST",
    data,
    null,
    true
  );
}

function removeParent(parent_id, type) {
  return apiCaller(
    `/api_relationship/invitation_delete?parent_id=${parent_id}&type=${type}`,
    "GET",
    {},
    null,
    true
  );
}

function getListMyParent() {
  return apiCaller(
    "/api_relationship/my_parent",
    "GET",
    {},
    null,
    true
  );
}

function getListInvitation() {
  return apiCaller(
    "/api_relationship/invitation_list",
    "GET",
    {},
    null,
    true
  );
}

function getListDataLearn() {
  return apiCaller("/api_student/data_learn_screen", "GET", {}, null, true);
}

function getHomeWorkByTeacher(teacher_id) {
  return apiCaller(
    "/api_student/homework_by_teacher?teacher_id=" + teacher_id,
    "GET",
    {},
    null,
    true
  );
}

function getHomeWorkByParent(parent_id, screen) {
  return apiCaller(
    "/api_student/homework_by_parent?parent_id=" + parent_id + (!!screen ? `&screen=${screen}` : ''),
    "GET",
    {},
    null,
    true
  );
}

function getListCurriculum() {
  return apiCaller(
    "/student/api_student_curriculum/courses_name_follow_class_v2",
    "GET",
    {},
    null,
    true
  );
}

function getExercise(
  teacher_id,
  class_id = null,
  limit = configConstants.DEFAULT_LIMIT,
  offset = 0
) {
  return apiCaller(
    "/api_user_exercise/my_homework?teacher=" +
      teacher_id +
      (class_id ? "&class_id=" + class_id : "") +
      `&limit=${limit}&offset=${offset}`,
    "GET",
    {},
    null,
    true
  );
}

function getOfflineAssessment() {
  return apiCaller("/api_class_offline/achievements", "GET", {}, null, true);
}

function getRankAssessment(limit, offset) {
  return apiCaller(
    `/api_student/ranking?limit=${limit}&offset=${offset}&type=total`,
    "GET",
    {},
    null,
    true
  );
}

function getLarningHistory(student_id, from_time, to_time) {
  return apiCaller(
    "/api_student/learning_history?student_id=" +
      student_id +
      "&from_time=" +
      from_time +
      "&to_time=" +
      to_time,
    "GET",
    {},
    null,
    false
  );
}

function getVocabulary() {
  return apiCaller("/api_student/student_vocab", "GET", {}, null, true);
}

function getVocabularyByClass(class_id) {
  return apiCaller(
    "/api_student/student_vocab_by_class?class_id=" + class_id,
    "GET",
    {},
    null,
    true
  );
}

function getVocabularyDetail(grade_id, student_id) {
  return apiCaller(
    `/api_student/student_vocab_detail?grade_id=${grade_id}&student_id=${student_id}`,
    "GET",
    {},
    null,
    true
  );
}

function getMyClassesV2() {
  return apiCaller(`/api_class/my_classes`, "GET", {}, null, true);
}

function getListDataLearnV2() {
  return apiCaller(
    "/api_student/data_learn_screen_v2",
    "GET",
    {},
    null,
    true,
    configConstants.API_URL_SETEST,
    false
  );
}

function getStudentLesson(lesson_id) {
  return apiCaller(
    "/api_lesson/launch_lesson?lesson_id=" + lesson_id,
    "GET",
    {},
    null,
    true
  );
  // return apiCaller(`/student/api_student_lesson/launch_homework?lesson_id=${lesson_id}&lesson_type=${lesson_type}&question_type=${question_type}`, 'GET', {}, null, true)
}

function getListCurriculumHistory(id, type, class_id) {
  return apiCaller(
    `/student/api_student_lesson/lesson_history?lesson_id=${id}&lesson_type=${type}&class_id=${class_id}`,
    "GET",
    {},
    null,
    true
  );
}

// Master Unit

function startDoMasterUnit(looking_back_id, type_lesson) {
  let params = `id=${looking_back_id}&type=${type_lesson}`;
  return apiCaller(
    `/student/api_student_lesson/looking_back_detail?${params}`,
    "GET",
    {},
    null,
    true
  );
}

function saveResultMasterUnit(data) {
  return apiCaller(
    `/api_log_learning/learning_improvement`,
    "POST",
    data,
    null,
    true
  );
}

function getListCurriculumHistoryMasterUnit(id, type) {
  return apiCaller(
    `/api_log_learning/master_unit_utilize?id=${id}&type=${type}`,
    "GET",
    {},
    null,
    true
  );
}

function getListCurriculumMasterUnit(id, status, limit, offset) {
  return apiCaller(
    `/student/api_student_lesson/looking_back?class_id=${id}&status=${status}&limit=${limit}&offset=${offset}`,
    "GET",
    {},
    null,
    true
  );
}

function getLessonMasterUnit(id) {
  return apiCaller(
    `/student/api_student_lesson/lesson_url?id=${id}`,
    "GET",
    {},
    null,
    true
  );
}

// Exam
function learn_exam(id, type) {
  return apiCaller(
    `/student/api_exam/exam_url?id=${id}&type=${type}`,
    "GET",
    {},
    null,
    true
  );
}

function getDetailMockTest(id) {
  return apiCaller(
    `/student/api_exam/mock_test_info?mock_test_id=${id}`,
    "GET",
    {},
    null,
    true
  );
}

function getDetailStudyGuild(id) {
  return apiCaller(
    `/api_lesson/homework?user_exercise_id=${id}`,
    "GET",
    {},
    null,
    true
  );
}

function getExamTestService(param) {
  let params = `purpose=${param.purpose}&limit=${param.limit}&offset=${param.offset}`;
  if (param.gradeId) {
    params = params + `&grade_id=${param.gradeId}`;
  }
  if (param.addressId) {
    params = params + `&address_id=${param.addressId}`;
  }
  return apiCaller(
    `/student/api_exam/mock_test?${params}`,
    "GET",
    {},
    null,
    true
  );
}

function getExamTestServiceNews(param) {
  let params = `limit=${param.limit}&offset=${param.offset}`;
  if (param.gradeId) {
    params = params + `&grade_id=${param.gradeId}`;
  }
  if (param.addressId) {
    params = params + `&address_id=${param.addressId}`;
  }

  if (param.purpose) {
    params = params + `&purpose=${param.purpose}`;
  }
  if (param.key_search) {
    params = params + `&key_search=${param.key_search}`;
  }
  return apiCaller(
    `/web_advertise/api_lecture/mock_test?${params}`,
    "GET",
    {},
    null,
    true
  );
}

function getExamTestHistoryService(gradeId, addressId) {
  let params = "";
  if (gradeId) {
    params = `grade_id=${gradeId}`;
  }
  if (addressId) {
    params =
      params.length === 0
        ? `address_id=${addressId}`
        : params + `&address_id=${addressId}`;
  }
  return apiCaller(
    `/student/api_exam/mock_test_history?${params}`,
    "GET",
    {},
    null,
    true
  ); // ?grade_id=${gradeId}&address_id=${addressId}
}

function getDetailExamTestHistoryService(id) {
  return apiCaller(
    `/student/api_exam/mock_test_history_detail?user_exam_result_id=${id}`,
    "GET",
    {},
    null,
    true
  ); // ?grade_id=${gradeId}&address_id=${addressId}
}

function startDoExamService(
  testId,
  typeExam,
  classId,
  unitId,
  isHomeWork,
  curriculumId
) {
  let params = `id=${testId}&type=${typeExam}&is_homework=${
    isHomeWork ? 1 : 0
  }`;
  if (classId) {
    params = params + `&class_id=${classId}`;
  }
  if (unitId) {
    params = params + `&unit_id=${unitId}`;
  }
  if (curriculumId) {
    params = params + `&curriculum_id=${curriculumId}`;
  }

  return apiCaller(
    `/student/api_exam/exam_v2?${params}`,
    "GET",
    {},
    null,
    true
  );
}

function saveExamService(data) {
  return apiCaller(`/api_log_learning/save_exam_v2`, "POST", data, null, true);
}

function getDetailExamResult(examId) {
  return apiCaller(
    `/student/api_student_lesson/load_exam_result?id=${examId}`,
    "GET",
    {},
    null,
    true
  );
}

function getListCurriculumFirstStep() {
  return apiCaller("/student/rl_005", "GET", {}, null, true);
}

function postCurriculumFirstStep(data) {
  return apiCaller("/student/rl_006", "POST", data, null, true);
}

function getCalenderList() {
  return apiCaller("/student/rl_001", "GET", {}, null, true);
}

function postStudySchedule(data) {
  return apiCaller("/student/rl_002", "POST", data, null, true);
}

function cancelEntranceExam() {
  return apiCaller("/student/rl_009", "GET", {}, null, true);
}

function getPlacementTest() {
  return apiCaller(
    "/student/api_rootlessness/placement_test_check",
    "GET",
    {},
    null,
    true
  );
}

function getResultPlacementTest() {
  return apiCaller("/student/rl_003", "GET", {}, null, true);
}

function getDetailHomeWorkExercise(user_exercise_id, library, exercise_type) {
  return apiCaller(
    "/student/api_homework/homework_detail?user_exercise_id=" +
      user_exercise_id +
      "&library=" +
      library +
      "&exercise_type=" +
      exercise_type,
    "GET",
    {},
    null,
    true
  );
}

function getInfoStudent(id) {
  return apiCaller(`/api_user/user?id=${id}`, "GET", {}, null, true);
}

function loadCurriculumRootLessness() {
  return apiCaller("/student/rl_007", "GET", {}, null, true);
}

async function getListClassStudentOnline(limit, offset, at_class) {
  let params = `limit=${limit}&offset=${offset}${
    at_class ? "&at_class=" + at_class : ""
  }`;
  try {
    let data = {
      method: "GET",
    };
    let res = await apiRequest(`/api_student/my_classes?${params}`, data);
    if (res.status === 200) {
      return res.data;
    }
    return [];
  } catch (err) {}
}
