import React, { Fragment, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { studentActions } from "../../_actions";
import { studentService, teacherService } from "../../_services";
import {
  homeType,
  studentConstants,
  configConstants,
  userConstants,
} from "../../_constants";
import _, { isEmpty } from "lodash";
import { Link, useLocation } from "react-router-dom";
import $ from "jquery";
import DoTestExamModal from "../StudentPage/exam-test/DoTestExam";
import LessonBase from "../../_base/LessonBase";
import { PopUpYesNo } from "../../_components/Popup";
import { history } from "../../_helpers";
import { PlacementTestPage } from "./FirstStep/PlacementTestPage/PlacementTestPage";
import { SliderHomePage } from "../../_components/Admin/Student/SlideHomePage";
import { convertNameSkillVn } from "../../_base/Validate";
import { teacherActions } from "../../_actions";
import "./style.scss";
import ButtonNews from "../../_components/Button/ButtonNews";
import { toggleSearchParam } from "../../_helpers/utils";
import { Mydata } from "../../_base/Mydata";

function HomePageStudent() {
  // const search = history.location.search;
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const dispatch = useDispatch();
  const learns = useSelector((state) => state.learns);
  const authentication = useSelector((state) => state.authentication);
  const itemActiveHomeStudent = useSelector(
    (state) => state.home_page_reducer.itemActiveHomeStudent
  );
  const isRootlessness = useSelector(
    (state) => state.rootlessness.isRootlessness
  );
  const scrollRef = useRef();
  const [isDidExercise, setIsDidExercise] = useState(params.get("isDidExercise"))
  const [showPopup, setShowPopup] = useState();

  const [numberMsg, setNumberMsg] = useState({
    number_msg_new: "0",
    number_system_new: "0",
  });
  
  const [active, setActive] = useState({
    type: "",
    id: "",
  });
  const [baseUrl, setBaseUrl] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [examSelected, selectExam] = useState(null);
  const [exeData, setexeData] = useState(null);
  const proposedCurriculum = useSelector(
    (state) => state.rootlessness.proposedCurriculum
  );
  const [isShowNavigateMasterUnit, showNavigateMasterUnit] = useState(false);

  // Check Lock Minitest
  const [lockMinitest, setLockMinitest] = useState(false);

  const chooseListCurriculum = useSelector(
    (state) => state.rootlessness.chooseListCurriculum
  );

  const startLearning = useSelector((state) => state.learns.startLearning);
  
  const dataListSlider = learns?.data?.list_teacher?.concat(learns?.data?.list_curriculum)?.concat(learns?.data?.list_parent)

  const handlepageshow = () => {
    $(".loading").addClass("hide");
  };

  useEffect(() => {
    window.addEventListener("pageshow", handlepageshow);

    return () => {
      window.removeEventListener("pageshow", handlepageshow);
    };
  }, []);

  useEffect(() => {
    if (
      window.location.pathname === `/${userConstants.ROLE_STUDENT}` &&
      authentication.last_login == null &&
      chooseListCurriculum
    ) {
      history.push(`/${userConstants.ROLE_STUDENT}/curriculum`);
    }


  }, []);

  useEffect(() => {
    if (
      window.location.pathname === `/${userConstants.ROLE_STUDENT}` &&
      !!proposedCurriculum &&
      !chooseListCurriculum
    ) {
      history.push(`/${userConstants.ROLE_STUDENT}/choose_curriculum`);
    }
  }, []);

  useEffect(() => {
    let isLoading = !$(".loading-side").hasClass("hide");
    setLoading(isLoading);
  }, [learns]);

  useEffect(() => {
    // teacherService.getInboxInfo().then((data) => {
    //   setNumberMsg({
    //     number_msg_new: data?.number_msg_new,
    //     number_system_new: data?.number_system_new,
    //   });
    // });
  }, []);

  // useEffect(() => {
  //   const handleVisibilityChange = () => {
  //     if (document.visibilityState === "hidden") {
  //       console.log("Tab bị chuyển đi.");
  //     } else if (document.visibilityState === "visible") {
  //       console.log("Tab được quay lại.");
  //     }
  //   };

  //   document.addEventListener("visibilitychange", handleVisibilityChange);

  //   // Cleanup
  //   return () => {
  //     document.removeEventListener("visibilitychange", handleVisibilityChange);
  //   };
  // }, []);

  useEffect(() => {
    if (scrollRef && scrollRef.current) {
      scrollRef.current.scrollTop = itemActiveHomeStudent?.scrollTop || 0;
    }
  }, [scrollRef, learns.data.list_teacher, learns.data.list_curriculum]);

  const getDataLearn = async () => {
    try {
      if (!dataListSlider?.length) {
        setLoading(true);
      }
      const learnsRes = await studentService.getListDataLearnV2();
      setBaseUrl(learnsRes.base_url);

      dispatch({
        type: userConstants.LOGIN,
        user: {
          ...authentication,
          total_diamond: learnsRes?.total_diamond,
        },
      });

      const learnAddType = {
        ...learnsRes,
        data: {
          ...learnsRes.data,
          list_curriculum: learnsRes.data.list_curriculum.map((curriculum) => ({
            ...curriculum,
            type_slide: "curriculum",
          })),
          list_teacher: learnsRes.data.list_teacher.map((teacher) => ({
            ...teacher,
            type_slide: "teacher",
          })),
          list_parent: learnsRes?.data?.list_parent?.map(parent => ({
            ...parent,
            type_slide: "parent",
          }))
        },
      };
      
      const isNotChange =
        JSON.stringify(learns?.data?.list_curriculum) ==
          JSON.stringify(learnAddType?.data?.list_curriculum) &&
        JSON.stringify(learns?.data?.list_teacher) ==
          JSON.stringify(learnAddType?.data?.list_teacher) &&
        JSON.stringify(learns?.data?.list_parent) ==
          JSON.stringify(learnAddType?.data?.list_parent);

      if (!isNotChange) {
        dispatch({
          type: studentConstants.GET_LIST_DATA_LEARN,
          learns: {
            ...learnsRes,
            data: {
              ...learnsRes.data,
              list_curriculum: learnsRes.data.list_curriculum.map(
                (curriculum) => ({
                  ...curriculum,
                  type_slide: "curriculum",
                })
              ),
              list_teacher: learnsRes.data.list_teacher.map((teacher) => ({
                ...teacher,
                type_slide: "teacher",
              })),
              list_parent: learnsRes?.data?.list_parent?.map(parent => ({
                ...parent,
                type_slide: "parent",
              }))
            },
          },
        });
      }
      setLoading(false);
    } catch (e) {
      $(".loading").addClass("hide");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isRootlessness) {
      getDataLearn();
    }
  }, []);

  function getExercises(type, id) {
    setActive({ type, id });
    var date = new Date().getTime()
    console.log("=====getExercises")
    if(!authentication.hasticket || authentication.hasticket?.ticket == -1 || (authentication.hasticket?.time_update && date - authentication.hasticket?.time_update > 21600000)){
      console.log("=====getExercises go")
      dispatch(studentActions.getlicense(authentication));
    }
    dispatch({
      type: homeType.SELECT_TEACHER_OR_CURRICULUM,
      payload: { type, id, scrollTop: scrollRef.current?.scrollTop || 0 },
    });

    if (type == "teacher") {
      dispatch(studentActions.getExerciseByTeacherIdV2(id, isDidExercise ? null : learns, true));
    } else if (type == "parent") {
      dispatch(studentActions.getExerciseByParentIdV2(id, isDidExercise ? null : learns, true));
    } else {
      authentication.last_login == null && isRootlessness
        ? ""
        : dispatch(studentActions.getExerciseByCurriculumId(id, isDidExercise ? null : learns, true));
    }
    setIsDidExercise (false)
  }

  const refreshData = async () => {
    if(isLoading) {
      return;
    }
    const {id, type} = active;
    setLoading(true);
    if (type == "teacher") {
      await dispatch(studentActions.getExerciseByTeacherIdV2(id, null, true));
    } else if (type == "parent") {
      await dispatch(studentActions.getExerciseByParentIdV2(id, null, true));
    } else {
      await dispatch(studentActions.getExerciseByCurriculumId(id, null, true));
    }
    setLoading(false);
  }

  let listDataExcercise = [];
  if (active.type === "teacher") {
    listDataExcercise = [
      ...(learns.exercises.list_home_work_new || []),
      ...(learns.exercises.list_home_work_complete || []),
    ];
  } else if (active.type === "parent") {
    listDataExcercise = [
      ...(learns.exercises.list_home_work_new || []),
      // ...(learns.exercises.list_home_work_complete || []),
    ];
  } else {
    listDataExcercise = learns.exercises.list_home_work || [];
  }

  const renderStatusExcercise = (status) => {
    if (_.isEqual(status, 1)) {
      return (
        <div className="__status_excercise_home_std">
          <img src={`/assets/images/student/home/ico_check.png`} />
        </div>
      );
    }
    // TODO with status lock
    // else if (_.isEqual(status, 0)) {
    //     return (
    //         <div className="__status_excercise_home_std">
    //             <img src={`/assets/images/student/home/ico_lock.png`}/>
    //         </div>
    //     )
    // }
  };
  let curriculumSelected = learns.data.list_curriculum?.find(
    (item) => item.curriculum_id === active.id
  );
  var countNotiMess =
    parseInt(numberMsg.number_msg_new) + parseInt(numberMsg.number_system_new);

  const goToExercise = (data) => {
    if (!!learns?.exercises?.require_learn_master_unit) {
      showNavigateMasterUnit(true);
    } else {
      setShowPopup(null);
      let typeUri = "";
      if (
        ["exam", "mini_test"].includes(data?.exercise_type || data?.lesson_type)
      ) {
        if (!!data?.is_lock) {
          setLockMinitest(true);
        } else {
          if(authentication.hasticket?.ticket < 1 && (data?.stt != "Unit 1" && data?.unit != "1" && !(data?.curriculum_name?.includes("Sunday Essential") && data?.unit_name == "Unit 1"))){
            window.alert("Bạn không thể truy cập nội dung này. Vui lòng nâng cấp tài khoản để tiếp tục!");
            return
          }
          typeUri = "exam";
          selectExam(data);
        }
        return;
      } else if (
        [
          "grammar",
          "listening",
          "pronunciation",
          "reading",
          "speaking",
          "writing",
          "vocabulary",
        ].includes(data?.exercise_type || data?.lesson_type)
      ) {
        typeUri = "lesson";
      } else if (
        ["project"].includes(data?.exercise_type || data?.lesson_type)
      ) {
        typeUri = "project";
      } else if (
        ["mini_test", "exam"].includes(data?.exercise_type || data?.lesson_type)
      ) {
        typeUri = "exam";
      } else if (
        ["homework"].includes(data?.exercise_type || data?.lesson_type)
      ) {
        typeUri = "homework";
      } else {
        typeUri = "lesson";
      }
      if (
        (data?.exercise_type === "project" ||
          (data?.exercise_type === "writing" && data?.question_type === "7") ||
          (data?.exercise_type === "speaking" &&
            data?.question_type === "3")) &&
        parseInt(data?.status) !== -1
      ) {
        setexeData(data);
        setShowPopup(data);
      } else {
        $(".loading").removeClass("hide");
        LessonBase._moveLesson(
          active.type === "teacher"
            ? LessonBase.Type.homework
            : LessonBase.Type.curriculum,
          authentication,
          data,
          null,
          1,
          window.location.href
        );
      }
    }
    // const url = `${configConstants.EXCERCISE_URL}index.php/example/lesson_demo?type=${typeUri}&id=${data?.lesson_id || data?.exercise_id}&token=${authentication?.jwt_token}`
    // console.log(url, '===> 1')
    // window.open(url, "_blank")
  };

  // Handle Go to homework
  const handleGoToHomework = () => {
    setShowPopup(null);
    $(".loading").removeClass("hide");
    LessonBase._moveLesson(
      active.type === "teacher"
        ? LessonBase.Type.homework
        : LessonBase.Type.curriculum,
      authentication,
      exeData,
      null,
      1
    );
  };

  // Go to history Exer
  const goToHistoryExer = () => {
    setShowPopup(null);
    history.push(
      `/${authentication.role}/curriculum/${showPopup.class_id}/skill/${showPopup.id}/lesson/${showPopup.exercise_type}/${showPopup.exercise_id}/exercise/${showPopup.id}/${showPopup.exercise_type}?from_screen=home_page&page=homepage&topic=${showPopup.exercise_topic}`
    );
    // history.push(
    //   `/${authentication.role}/curriculum/${showPopup.class_id}/skill/${showPopup.id}/lesson/${showPopup.exercise_type}/${showPopup.exercise_id}/history?from_screen=home_page&page=homepage`
    // );
  };

  // Render List Exercise
  const renderListExercises = (exercise, index) => {

    return (
      <div
        key={exercise?.id + "_" + index?.toString()}
        className="homepage_exercise_item flex-align pointer_cursor"
        style={{
          borderRight: `7.5px solid ${
            exercise?.status == "1"
              ? "#8DC63F"
              : exercise?.status == "0"
              ? "#F8AF36"
              : "#EB5757"
          }`,
        }}
        onClick={() => goToExercise(exercise)}
      >
        <div className="homepage_exercise_img">
          <img
            src={`/assets/images/teacher/giaobai/${
              exercise.exercise_type?.toLocaleLowerCase() ||
              exercise.lesson_type?.toLocaleLowerCase()
            }.png`}
            alt="details"
            className="cover_image"
          />
        </div>

        <div className="homepage_exercise_desc" style={{flex: 1, paddingRight: 16}}>
          <div className=" font_myriad_bold fz-11-25 color-text">
            {exercise?.exercise_name ?? exercise?.lesson_name}
          </div>
          <div className=" fz-11-25 color-text line_height_normal">
            {`${convertNameSkillVn(
              exercise?.exercise_type ?? exercise?.lesson_type
            )} - ${
              active?.type == "teacher"
                ? `${
                    exercise?.unit
                      ? `Unit ${exercise?.unit} `
                      : exercise?.stt
                      ? `${exercise?.stt} `
                      : ""
                  }`
                : exercise?.stt
                ? `${exercise?.stt} `
                : ""
            } ${
              exercise?.unit_name && !exercise?.is_curriculum_loseroot
                ? `: ${exercise?.unit_name}`
                : ""
            }`}
          </div>
          <div className=" fz-11-25 color-text line_height_normal">
            {exercise?.curriculum_name}
          </div>
          <div style={{display: 'flex', justifyContent:'space-between'}}>
            <div
              className="font_myriad_bold fz-11-25 line_height_normal"
              style={{
                color:
                  exercise?.status == "1"
                    ? "#8DC63F"
                    : exercise?.status == "0"
                    ? "#F8AF36"
                    : "#EB5757",
              }}
            >
              {exercise?.status == "1"
                ? "Hoàn thành"
                : exercise?.status == "0"
                ? "Chờ chấm"
                : "Chưa làm"}
            </div>
            <span>{"Mã bài: " + (exercise?.lesson_id || exercise?.exercise_id)}</span>
          </div>
        </div>
      </div>
    );
  };

  // Handle View More
  const handleViewMore = () => {
    if (!!learns?.exercises?.require_learn_master_unit) {
      showNavigateMasterUnit(true);
    } else {
      dispatch({
        type: studentConstants.CLEAR_STATUS_HOMEWORK,
      });
      const urlView =
        "/" +
        authentication.role +
        (active?.type === "teacher"
          ? "/homework_by_teacher/" +
            (curriculumSelected ? curriculumSelected?.id : active?.id)
          : active?.type === "parent"
          ? "/homework_by_parent/" +
            (curriculumSelected ? curriculumSelected?.id : active?.id)
          : learns && learns?.exercises?.require_learn_master_unit
          ? "/curriculum/" +
            (curriculumSelected ? curriculumSelected?.id : active?.id) +
            "?from=home_screen"
          : `/curriculum/${curriculumSelected?.id}/skill/${learns?.exercises?.list_home_work[0]?.unit_id}?page=homepage`);
      history.push(urlView);
    }
  };
  
  // handle view detail
  const handleViewDetail = () => {
    if (curriculumSelected) {
      localStorage.setItem("curriculum_id_Selected", JSON.stringify(curriculumSelected?.id));
      const urlView =
        "/" +
        authentication.role + "/curriculum"
      history.push(urlView);
    };
    handleViewMore()
  };

  // Handle update Profile total_diamond
  // useEffect(() => {
  //   dispatch(teacherActions.getProfileV2(authentication.id));
  // }, []);
  
  return (
    <>
      {authentication.last_login == null && isRootlessness ? (
        <PlacementTestPage />
      ) : (
        <div
          className="sunE-right-container"
          style={{
            paddingBottom: 0,
            display: "flex",
            flexDirection: "column",
            height: "100vh",
          }}
        >
          {/* {authentication.role === "student" && (
            <div className="text-right d-flex align-items-center justify-content-end">
              <Link
                to={
                  "/" +
                  authentication.role +
                  "/more/message?active=personal&page=homepage"
                }
              >
                <div className="noti-message-custom d-flex mr-20">
                  <img
                    className="icon-noti-mess"
                    src="/assets/images/icon/ico_notification.png"
                    alt="teacher_detail"
                  />
                </div>
              </Link>
            </div>
          )} */}
          {lockMinitest ? (
            <PopUpYesNo
              title="Thông báo"
              onClickYes={() => {
                setLockMinitest(false);
              }}
              message={
                "Các bài tập Grammar phải đạt từ 7 điểm trở lên để mở bài kiểm tra Grammar."
              }
              labelYes={"Đóng"}
              hideButtonNo
            />
          ) : null}
          <div
            className="student-container student-index student-homepage display_flex"
            style={{ paddingBottom: 0, flex: 1 }}
          >
            <div className="display_flex content_homepage_student col-100">
              <div className=" homepage-data">
                <div className="flex flex-m align-item-center">
                  <div className="font_myriad_bold fz-15 color-text text-align-left title-homepage-content">
                    Học tiếp
                  </div>
                  <div style={{display: 'flex', gap: 16, marginRight: 8, alignItems: 'center', marginBottom: 8}}>
                    {!isLoading && !!listDataExcercise?.length && <ButtonNews styleProps={{justifyContent: 'flex-end', padding: 0}} width={50} height={50} backgroundColor={'transparent'} onClick={handleViewDetail}>
                      <img src="assets/images/homeNews/icon/icon_view_detail_home_page.png" style={{width: '40px'}}/>
                    </ButtonNews>}
                    <div className="refresh-curriculum-btn" onClick={refreshData} style={{width: 40, height: 40}}>
                      <img src="/assets/images/icon_refresh_curriculum.png" style={{width: '100%', height: '100%', borderRadius: '50%'}}/>
                    </div>
                  </div>
                </div>
                <div className=" flex-1 hompage-list-exercise scrollbar-custom ">
                  {!isLoading ? (
                    listDataExcercise && listDataExcercise?.length > 0 ? (
                      <Fragment>
                        {listDataExcercise
                          ?.slice(0, 6)
                          ?.map((exercise, index) =>
                            renderListExercises(exercise, index)
                          )}
                        <div
                          onClick={handleViewMore}
                          className="homepage-btn-more fz-11-25 text_underline pointer_cursor"
                        >
                          Xem thêm
                        </div>
                      </Fragment>
                    ) : (
                      <div className="fz-11-25 text_exercise--empty color-text text-align-center">
                        {active.type == "curriculum"
                          ? "Giáo trình lớp đang được cập nhật"
                          : "Hiện tại bạn chưa được giao bài nào"
                          }
                      </div>
                    )
                  ) : (
                    <div className="col-100 flex-center flex-1">
                      <div className="loader"></div>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex-1 ">
                <div className="slider_homepage_student">
                  <SliderHomePage
                    role={authentication.role}
                    dataList={dataListSlider}
                    getExercises={getExercises}
                    examSelected={examSelected}
                    setLoading={setLoading}
                  />
                </div>
              </div>
              {/* 
              <div className="flex-1 ml-20 shedu-item-container">
                {!isLoading &&
                  (isEmpty(listDataExcercise) ? (
                    <div className="box-shadow shedu-mo-item center-flex">
                      <div>
                        <img
                          src="/assets/images/student/bg_no_homework.png"
                          alt="bg_no_homework"
                        />
                        {active.type === "teacher" ? (
                          <p>Hiện tại bạn chưa được giao bài nào</p>
                        ) : (
                          <p>Giáo trình lớp đang được cập nhật</p>
                        )}
                      </div>
                    </div>
                  ) : (
                    <Fragment>
                      <div className="shedu-first-item box-shadow">
                        <div
                          className="img-shedu center-flex"
                          style={{ position: "relative" }}
                        >
                          <img
                            src={
                              listDataExcercise[0].status == 1
                                ? `/assets/images/student/homeworkdone/${
                                    active.type === "teacher"
                                      ? listDataExcercise[0].exercise_type
                                      : listDataExcercise[0].lesson_type
                                  }.png`
                                : `/assets/images/student/home/${
                                    active.type === "teacher"
                                      ? listDataExcercise[0].exercise_type
                                      : listDataExcercise[0].lesson_type
                                  }.png`
                            }
                            alt="ico_listening_large"
                            onClick={() => goToExercise(listDataExcercise[0])}
                          />
                        </div>
                        <h2>
                          {active.type === "teacher"
                            ? listDataExcercise[0].exercise_name
                            : listDataExcercise[0].lesson_name}
                        </h2>
                        <p>
                          {active.type === "teacher"
                            ? listDataExcercise[0].topic
                            : listDataExcercise[0].lesson_topic}
                        </p>
                      </div>
                      <div className="shedu-list-more-gr box-shadow">
                        <LazyLoad className="flex-m">
                          {listDataExcercise.map((data, i) => {
                            if (i > 0 && i < 4) {
                              let avatar =
                                active.type === "teacher"
                                  ? data.exercise_type
                                  : data.lesson_type;
                              return (
                                <div className="flex-1" key={"curriculum_" + i}>
                                  <div className="shedu-more-item">
                                    <div
                                      className="img center-flex rel"
                                      style={{ position: "relative" }}
                                    >
                                      <img
                                        src={
                                          data.status == 1
                                            ? `/assets/images/student/homeworkdone/${avatar}.png`
                                            : `/assets/images/student/home/${avatar}.png`
                                        }
                                        alt={avatar}
                                        onClick={() => goToExercise(data)}
                                      />
                                    </div>
                                    <p>
                                      {active.type === "teacher"
                                        ? data.exercise_name
                                        : data.lesson_name}
                                    </p>
                                  </div>
                                </div>
                              );
                            } else {
                              return null;
                            }
                          })}
                          {!isEmpty(listDataExcercise) && (
                            <Link
                              to={
                                "/" +
                                authentication.role +
                                (active?.type === "teacher"
                                  ? "/homework_by_teacher/" +
                                    (curriculumSelected
                                      ? curriculumSelected?.id
                                      : active?.id)
                                  : learns &&
                                    learns?.exercises?.require_learn_master_unit
                                  ? "/curriculum/" +
                                    (curriculumSelected
                                      ? curriculumSelected?.id
                                      : active?.id) +
                                    "?from=home_screen"
                                  : `/curriculum/${curriculumSelected?.id}/skill/${learns?.exercises?.list_home_work[0]?.unit_id}?page=homepage`)
                              }
                              onClick={() =>
                                dispatch({
                                  type: studentConstants.CLEAR_STATUS_HOMEWORK,
                                })
                              }
                            >
                              <div className="flex-1">
                                <div className="shedu-more-item">
                                  <div className="img center-flex rel">
                                    <img
                                      src="/assets/images/student/ico_add_blue.png"
                                      alt="ico_add_blue"
                                    />
                                  </div>
                                  <p>Xem chi tiết</p>
                                </div>
                              </div>
                            </Link>
                          )}
                        </LazyLoad>
                      </div>
                    </Fragment>
                  ))}
              </div> */}
            </div>
          </div>
          {examSelected && (
            <DoTestExamModal
              setExamSelected={selectExam}
              isShow={examSelected !== null}
              classId={examSelected.class_id}
              unitId={examSelected?.unit_id}
              onClose={() => {
                selectExam(null);
                getDataLearn();
                if (isDidExercise) {
                  toggleSearchParam({
                    key: 'isDidExercise'
                  })
                  setIsDidExercise(false)
                  getExercises(itemActiveHomeStudent?.type, itemActiveHomeStudent?.id);
                }
              }}
              isHomeWork={active.type == "teacher" ? true : false}
              mockTestId={
                !!active.type && active.type == "teacher"
                  ? examSelected?.id
                  : examSelected?.lesson_id
              }
              type={examSelected.lesson_type || examSelected.exercise_type}
              heading={examSelected?.exam_name || examSelected?.name || examSelected?.lesson_name || examSelected?.exercise_name || ''}
              onSave={() => {setIsDidExercise(true)}}
            />
          )}
          {showPopup && (
            <PopUpYesNo
              onClickYes={() =>
                parseInt(showPopup?.status) === 0
                  ? handleGoToHomework()
                  : goToHistoryExer()
              }
              onClickNo={() => setShowPopup(null)}
              labelNo={"Không"}
              message={
                parseInt(showPopup?.status) === 0
                  ? "Bạn đã nộp bài trước đó, bạn có chắc chắn muốn nộp lại?"
                  : parseInt(showPopup?.status) === 1 &&
                    "Bạn đã nộp bài và giáo viên đã chấm. Bạn có muốn xem bài chữa không?"
              }
            />
          )}
          {isShowNavigateMasterUnit && (
            <PopUpYesNo
              onClickYes={() => {
                localStorage.removeItem("tabSelectedMasterunit");
                history.push(
                  "/" +
                    authentication.role +
                    `/curriculum/${listDataExcercise[0]?.class_id}/master_unit`
                );
              }}
              onClickNo={() => showNavigateMasterUnit(false)}
              message={
                "Bạn hãy hoàn thành bài tập trong Master Unit để tiếp tục."
              }
              labelNo={"Đóng"}
              labelYes={"Đồng ý"}
              width={"280px"}
            />
          )}
        </div>
      )}
    </>
  );
}

export { HomePageStudent };
